import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { parseSearchQuery } from '../utils/common';
import { useDeleteContactMutation, useGetContactQuery } from '../api/contactEndpoint';
import { PageSkeletton } from '../components/shared/pageSkeletton';
import { History } from '../components/shared/history';
import { ProfileInfo } from '../components/shared/profileInfo';
import { ContactDetail, ProfileInfoData } from '../models';
import FileList from '../components/micro/fileList';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ContactModal } from '../components/modals/contactModal';
import { formatPhone } from '../utils/common';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteModal from '../components/modals/deleteModal';
import { useSelector } from 'react-redux';
import { RootState } from '../models/redux';
import FileService from '../api/file';
import { UploadModal } from '../components/modals/uploadModal';
import CircularProgress from '@mui/material/CircularProgress';
import { useInvalidateCacheMutation } from '../api/fileEndpoint';
import { useDispatch } from 'react-redux';
import { actions as requestActions } from '../redux/slicers/requests';
import Button from '@mui/material/Button';
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  elevation: 0,
}));
const fileTypes = ['JPG', 'PNG', 'GIF', 'PDF', 'DOC', 'DOCX', 'JPEG'];

const getProfileInfo = (contact: ContactDetail): ProfileInfoData => {
  const type = `${contact.type[0]}${contact.type.slice(1).toLowerCase()}`;
  return {
    type,
    name: contact.firstname +' '+ contact.lastname,
    email: contact.email ?? '-',
    phone: contact.phone ?? '-' ,
    jobTitle: contact.jobTitle,
    address: contact.address ?? '-',
    country: contact.country ?? '-',
    linkedin: contact.linkedin ?? '-',
  };
};

const historyTitleStyle: React.CSSProperties = {
  textAlign: 'left',
  paddingTop: '10px',
  paddingLeft: '10px',
};

const historyTitleContentStyle: React.CSSProperties = {
  marginBottom: '4px',
  marginTop: '0px',
};

export function ContactPage() {
  const [openDelete, setOpenDelete] = React.useState(false);
  const [expanded, setExpanded] = React.useState(true);
  const [openFile, setOpenFile] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const locationProps = parseSearchQuery(location.search.slice(1));
  const { data: contact, isFetching } = useGetContactQuery(locationProps.id);
  const [editContact, setEditContact] = React.useState(false);
    const hasRights = (ownerId: number) =>
      ownerId === parseInt(userId) || isAdmin;
  const isAdmin = useSelector((state: RootState) => state.login.isAdmin);
  const [deleteContact] = useDeleteContactMutation();
  const [invalidateCache] = useInvalidateCacheMutation();
  const userId = useSelector((state: RootState) => state.login.userId);
  const dispatch = useDispatch();
  const handleFailure = (e: any) => {
    console.log(e);
    dispatch(requestActions.requestFailure('Something went wrong.'));
  };
  const handleUpload = async (
    files: FileList | null,
    setProgress: React.Dispatch<React.SetStateAction<number>>,
    setUploadStarted: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (files !== null && contact) {
      setUploadStarted(true);
      const filesArray = Array.from(files);
      try {
        filesArray.forEach(async (file) => {
          try {
            const response = await FileService.upload(file, contact.id, 'contact', (event: any) => {
              setProgress(Math.round((100 * event.loaded) / event.total));
            });
            setUploadStarted(false);
            dispatch(requestActions.requestSuccess());
            invalidateCache();
            setOpenFile(false);
          } catch (e) {
            handleFailure(e);
          }
        });
      } catch (e) {
        handleFailure(e);
      }
    }
  };

  const handleDelete = async () => {
    if (contact) {
      const resp = await deleteContact(contact.id);
      if ('error' in resp) {
        dispatch(requestActions.requestFailure('Something went wrong'));
      } else {
        dispatch(requestActions.requestSuccess());
        navigate('/contacts')
      }
    } else {
      dispatch(requestActions.requestFailure('Something went wrong'));
    }
  };;

  return (
    <PageSkeletton
      name={`${contact?.firstname} ${contact?.lastname} Profile Page`}
    >
      <>
        {isAdmin && <DeleteModal
        open={openDelete}
        setOpen={setOpenDelete}
        handleConfirm={handleDelete}
      />}
        <UploadModal
          allowed={fileTypes}
          open={openFile}
          setOpen={setOpenFile}
          handleUpload={handleUpload}
        />
        {contact && (
          <ContactModal
            open={editContact}
            setOpen={setEditContact}
            type="edit"
            editData={contact}
          />
        )}
        <Grid container spacing={2}>
          <Grid item container md={9}>
            <Grid item md={2}>
              <img
                src="../../blank_contact_profile.png"
                width="100"
                height="100"
              />
            </Grid>
            <Grid item md={9}>
              {contact && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <h1 style={{ marginBottom: '0px', marginTop: '0px' }}>
                    {contact?.firstname} {contact?.lastname}{' '}
                    {isAdmin ? (
                      <Tooltip title="Delete">
                        <IconButton size="small" onClick={() => {
                          setOpenDelete(true)
                        }}>
                          <DeleteIcon color='error'/>
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <></>
                    )}
                  </h1>
                  <div>
                    {`${contact?.jobTitle} at `}
                    <Link to={`/company?id=${contact?.company.id}`}>
                      {contact?.company.label}
                    </Link>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginRight: '20px',
                      }}
                    >
                      <EmailIcon
                        fontSize="small"
                        color="disabled"
                        style={{
                          marginRight: '5px',
                        }}
                      />
                      <div>{contact?.email}</div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginRight: '20px',
                      }}
                    >
                      <LocalPhoneIcon
                        fontSize="small"
                        color="disabled"
                        style={{
                          marginRight: '5px',
                        }}
                      />
                      {formatPhone(contact?.phone ?? null)}
                    </div>
                  </div>
                </div>
              )}
              {isFetching && <CircularProgress />}
            </Grid>
          </Grid>
          <Grid item md={9}>
            <Button onClick={() => setOpenFile(true)}>Attach a file</Button>
          </Grid>
          <Grid item md={9}>
            <Item>{contact && <FileList files={contact.files} />}</Item>

            <Accordion
              expanded={expanded}
              onChange={() => {
                setExpanded(!expanded);
              }}
              disableGutters
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div style={historyTitleStyle}>
                  <h1 style={historyTitleContentStyle}>History</h1>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {contact && (
                  <>
                    <History
                      history={contact.historyData}
                      contactId={parseInt(locationProps.id)}
                      isFetching={isFetching}
                    />
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item md={3}>
            <div
              style={{
                ...historyTitleStyle,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <h3 style={historyTitleContentStyle}>Contact info</h3>
              {contact && hasRights(contact.ownerId) && (
                <Tooltip title="Edit">
                  <IconButton
                    onClick={() => setEditContact(true)}
                    style={{ position: 'relative', bottom: '5px' }}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </div>
            <Item>
              {contact && (
                <ProfileInfo
                  data={getProfileInfo(contact)}
                  isFetching={isFetching}
                />
              )}
            </Item>
            <div style={historyTitleStyle}>
              <h3 style={historyTitleContentStyle}>Company Info</h3>
            </div>
            <Item>
              <ProfileInfo
                data={{ companyName: contact?.company.label }}
                isFetching={isFetching}
              />
            </Item>
          </Grid>
        </Grid>
      </>
    </PageSkeletton>
  );
}
