import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Button from '@mui/material/Button';
import { useGetIndustriesQuery} from '../api/industryEndpoint';
import { Option } from '../models';
import { AddModal, EditModal, DeleteModal } from '../components/modals/industryModals';
import { PageSkeletton } from '../components/shared/pageSkeletton';


const IndustryButtons = (props: { industry: Option }) => {
  const [editOpen, setEditOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);

    const handleEdit = () => {
        setEditOpen(true);
    };
    const handleDelete = () => {
        setDeleteOpen(true);
    };
    return (
        <>
        <EditModal industry={props.industry} open={editOpen} setOpen={setEditOpen}></EditModal>
        <DeleteModal industry={props.industry} open={deleteOpen} setOpen={setDeleteOpen}></DeleteModal>
        <IconButton
          onClick={handleEdit}
          aria-label="Edit Industry"
          component="span"
        >
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={handleDelete}
          aria-label="Delete Industry"
          component="span"
        >
          <DeleteForeverIcon />
        </IconButton>
      </>
    );
}

export function IndustryPage() {
  const [addOpen, setaddOpen] = React.useState(false);
  const { data } = useGetIndustriesQuery();
  return (
    <PageSkeletton name='Industry'>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <AddModal open={addOpen} setOpen={setaddOpen} />
        <Button
          variant="contained"
          onClick={() => {
            setaddOpen(true);
          }}
        >
          Add new industry
        </Button>
        <br />
        <br />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="industry table">
            <TableBody>
              {data &&
                data.map((row: Option) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.label}
                    </TableCell>
                    <TableCell align="right">
                      <IndustryButtons industry={row} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </PageSkeletton>
  );
}
