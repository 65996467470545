import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { actions as requestActions } from '../../redux/slicers/requests';
import {useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../models/redux';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



export function SuccessSnackbar() {
  const open = useSelector(
    (state: RootState) => state.requests.requestSuccess
  );
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(requestActions.reset());
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={handleClose}
        autoHideDuration={3000}
        key="successSnackbar"
          >
              <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Success.
              </Alert>
        </Snackbar>
    </div>
  );
}

export function ErrorSnackbar() {
  const open = useSelector((state: RootState) => state.requests.requestFailure);
  const error = useSelector((state: RootState) => state.requests.error);
  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(requestActions.reset())
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={handleClose}
        autoHideDuration={3000}
        key="successSnackbar"
      >
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </div>
  );
}
