import * as React from 'react';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { User } from '../../models';
import PasswordDialog from '../modals/passwordDialog';
import EmailDialog from '../modals/emailDialog';
import { UserAddModal } from '../modals/userModal';
import Tooltip from '@mui/material/Tooltip';
import PasswordIcon from '@mui/icons-material/Password';

const parseDate = (dateString: string) => {
  const date = new Date(Date.parse(dateString));
  return date.toLocaleString();
};

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

export default function UsersList(props: { users?: User[] }) {
  const [openPassword, setOpenPassword] = React.useState(false);
  const [openEmail, setOpenEmail] = React.useState(false);
  const [user, setUser] = React.useState<User | null>(null);
  const [openCreate, setOpenCreate] = React.useState(false);
  const handleEditPassword = (id: number) => {
    setUser(props.users?.find((user) => user.id === id) ?? null);
    setOpenPassword(true);
  };
  const handleEditEmail = (id: number) => {
    setUser(props.users?.find((user) => user.id === id) ?? null);
    setOpenEmail(true);
  };
  return (
    <div style={{ maxWidth: '720px', margin: 'auto' }}>
      <PasswordDialog
        user={user}
        open={openPassword}
        setOpen={setOpenPassword}
      />
      <EmailDialog user={user} open={openEmail} setOpen={setOpenEmail} />
      <UserAddModal open={openCreate} setOpen={setOpenCreate} />
      <Grid container>
        <Grid item md={9}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            Edit Users
          </Typography>
        </Grid>
        <Grid sx={{ mt: 4, mb: 2, paddingLeft: 5.1 }} item md={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenCreate(true)}
          >
            Create User
          </Button>
        </Grid>
      </Grid>
      <Demo>
        <List>
          {props.users?.map((user) => {
            return (
              <ListItem
                secondaryAction={
                  <>
                    <Tooltip title="Edit Email">
                      <IconButton
                        edge="end"
                        aria-label="Edit Email"
                        onClick={() => {
                          handleEditEmail(user.id);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit Password">
                      <IconButton
                        edge="end"
                        aria-label="Edit Password"
                        onClick={() => {
                          handleEditPassword(user.id);
                        }}
                      >
                        <PasswordIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                }
                key={user.id}
              >
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: user.isAdmin ? 'green' : 'blue' }}>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={user.email}
                  secondary={`${
                    user.isAdmin ? 'Administrator -' : 'Standard -'
                  } Created: ${parseDate(user.createdAt)}`}
                />
              </ListItem>
            );
          })}
        </List>
      </Demo>
    </div>
  );
}
