import { Placement, PlacementRecord } from "../../../models";
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { formatPhone, getNameFromEmail } from '../../../utils/common';
import FileService from '../../../api/file';
import { Button, Chip } from "@mui/material";
import { Link } from "react-router-dom";

const TEXTSIZE = `${window.innerWidth * 0.2}px`;
export const PlacementRow = ({
  placement,
  isRecord,
}: {
  placement: PlacementRecord | Placement;
  isRecord?: Boolean;
  }) => {
  const handleDownload = async (id: string, name: string) => {
    await FileService.getFile(id, name);
  };

  return (
    <TableRow
      key={placement.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {isRecord && (
          <Chip
            label="Placement"
            color="warning"
            size="small"
            sx={{ marginBottom: 2 }}
          />
        )}

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Link
            to={`/placement?n=${placement.company.name}&company=${placement.company.id}`}
          >
            {placement.candidateFullname}
          </Link>
          <span
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              width: TEXTSIZE,
            }}
          >
            {placement.title && (
              <>
                <span>{placement.title} in </span>
                <span
                  style={{
                    fontWeight: 'bold',
                    color: '#3d5c5c',
                  }}
                >
                  {`${placement.country}`}
                </span>
              </>
            )}
          </span>
          {placement.company.industry && (
            <span>{placement.company.industry.label}</span>
          )}
        </div>
      </TableCell>
      <TableCell align="right">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>{placement.email}</span>
          <span>{formatPhone(placement.phone)}</span>
        </div>
      </TableCell>
      {!isRecord && (
        <>
          {
            <TableCell align="right">
              {placement.attachement && (
                <Button
                  onClick={() => {
                    handleDownload(
                      placement.attachement.id,
                      placement.attachement.name
                    );
                  }}
                >
                  <span
                    style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      width: TEXTSIZE,
                    }}
                  >
                    {' '}
                    {placement.attachement.name}
                  </span>
                </Button>
              )}
            </TableCell>
          }
          <TableCell align="right">
            <a href={`/company?id=${placement.company.id}`}>
              <span> {placement.company.name}</span>
            </a>
          </TableCell>
        </>
      )}
      {isRecord && (
        <>
          <TableCell align="right">
            <span>
              {(placement as PlacementRecord).owner
                ? getNameFromEmail((placement as PlacementRecord).owner.email)
                : 'Name Unknown'}
            </span>
          </TableCell>
          <TableCell align="right">
            <span>
              {(placement as PlacementRecord).createdAt
                ? new Date(
                    (placement as PlacementRecord).createdAt
                  ).toUTCString()
                : 'Date Unknown'}
            </span>
          </TableCell>
        </>
      )}
    </TableRow>
  );
};