import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { User } from '../../models';
import { useEditUserEmailMutation } from '../../api/usersEndpoint';
import { emailValidation } from '../../utils/validation';
import { emailValidationMessages } from '../../models/validation';
import { useDispatch } from 'react-redux';
import { actions as requestActions } from '../../redux/slicers/requests';
export default function EmailDialog(props: {
  user: User | null;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [email, setEmail] = React.useState<string>(props.user?.email ?? '');
  const [error, setError] = React.useState<emailValidationMessages>(
    emailValidationMessages.VALID
  );
  const dispatch = useDispatch();
  const [editUserEmail] = useEditUserEmailMutation();

  const handleChange = (e: any) => {
    setEmail(e.target.value);
  };
  const handleClose = () => {
    setEmail(props.user?.email ?? '');
    props.setOpen(false);
  };
  const handleSubmit = async () => {
    if (emailValidation(email) === emailValidationMessages.VALID) {
      setError(emailValidationMessages.VALID);
      const resp = await editUserEmail({
        id: props.user?.id ?? 0,
        email: email,
      });
      if ('error' in resp) {
        dispatch(requestActions.requestFailure('Something went wrong'));
      } else {
        dispatch(requestActions.requestSuccess());
        handleClose();
      }
    } else {
      setError(emailValidation(email));
    }
  };
  React.useEffect(() => {
    if (props.user?.email) setEmail(props.user?.email);
  }, [props])
  
  return (
    <>
      <Dialog open={props.open} onClose={handleClose}>
        <DialogTitle>Edit email</DialogTitle>
        <DialogContent sx={{ minWidth: '400px' }}>
          <DialogContentText>
            Please enter new email for the user {props.user?.email}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            error={error !== emailValidationMessages.VALID}
            helperText={error === emailValidationMessages.VALID ? '' : error}
            id="email"
            value={email}
            onChange={handleChange}
            label="New email"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
