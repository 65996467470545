import { ProfileInfoData } from "../../models"
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from "react-router-dom";

const titleMapping: ProfileInfoData = {
    "country": "Country",
    "name": "Name",
    "address": "Work Address",
    "background": "Background info",
    "phone": "Work phone",
    "email": "Work email",
    "jobTitle": "Job title",
    "companyName": "Company Name",
    "linkedin": "LinkedIn",
    "type": "Type",
}

const PADDINGLEFT = "10px"
const titleStyle: React.CSSProperties = {
    textAlign: "left",
    paddingLeft: PADDINGLEFT,
    fontWeight: "bold",
    color: "grey",
};

const contentStyle: React.CSSProperties = {
    textAlign: "left",
    paddingLeft: PADDINGLEFT
}

export const ProfileInfo = (props: { data?: ProfileInfoData, isFetching: boolean }) => {
    return <>
        {props.isFetching && <CircularProgress/>}
        {props.data && 
            Object.keys(props.data).map((title: string) => {
            return (
              <div
                style={{
                  paddingTop: '8px',
                  paddingBottom: '8px',
                }}
                key={'info-content' + title}
              >
                <div style={titleStyle} key={'info-content-title' + title}>
                  {titleMapping[title]}
                </div>
                {props.data &&
                  props.data[title] &&
                  (title === 'linkedin' && props.data[title] !== '-' ? (
                    <div style={contentStyle}>
                      <a
                        href={props.data[title] ?? ''}
                        key={'info-content-content' + title}
                      >
                        {props.data[title] ?? '-'}
                      </a>
                    </div>
                  ) : (
                    <div
                      style={contentStyle}
                      key={'info-content-content' + title}
                    >
                      {props.data[title] ?? '-'}
                    </div>
                  ))}
              </div>
            );
            })
    }</>;
}