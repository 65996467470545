import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { SearchEntity } from '../../../models/index';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import { useNavigate } from 'react-router-dom';

function CustomizedInputBase(props: {
  name?: string;
  searchEntity?: SearchEntity;
}) {
  const [value, setValue] = React.useState<string>(
    props.name ? props.name : ''
  );
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  //NAVIGATION
  const navigate = useNavigate();
  const handleNavigate = (searchedWord: string) => {
    const map = {'company': 'companies', 'contact': 'contacts'};
    navigate(`/${map[props.searchEntity as SearchEntity]}?query=${searchedWord}`);
  };
  return (
    <Paper
      component="form"
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        marginTop: '9px',
      }}
      onSubmit={(e: any) => {
        e.preventDefault();
        handleNavigate(value);
      }}
    >
      <InputBase
        value={value}
        onChange={handleInputChange}
        sx={{ ml: 1, flex: 1 }}
        placeholder="Enter a contract's name, or company's name"
        inputProps={{
          'aria-label': "Enter a contract's name, or company's name",
        }}
      />
      <Button
        type="submit"
        sx={{ p: '10px' }}
        aria-label="search"
        variant="contained"
      >
        Search
      </Button>
    </Paper>
  );
}

export const SearchBar = (props: { name?: string; entity?: string }) => {
  // Link search entity with options
  const [searchedEntity, setsearchedEntity] = React.useState<SearchEntity>(
    props.entity ? (props.entity as SearchEntity) : 'company'
  );
  const handleSearchedEntityChange = (event: SelectChangeEvent) => {
    setsearchedEntity(event.target.value as SearchEntity);
  };

  return (
    <>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <FormControl
          sx={{ m: 1, minWidth: 120, maxHeight: 10, marginRight: 5 }}
        >
          <InputLabel id="demo-simple-select-helper-label">Search</InputLabel>

          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={searchedEntity}
            label="search"
            onChange={handleSearchedEntityChange}
          >
            <MenuItem value={'company'}>company</MenuItem>
            <MenuItem value={'contact'}>contact</MenuItem>
          </Select>
        </FormControl>
        <CustomizedInputBase name={props.name} searchEntity={searchedEntity} />
      </div>
    </>
  );
};
