import { api } from './api';
import { Placement } from '../models';
import { constructQuery } from '../utils/common';
const placementsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPlacements: builder.query<
      Placement[],
      {
        companies?: number[];
        owners?: number[];
        industries?: number[];
        countries?: string[];
      }
    >({
      query: ({ companies, owners, industries, countries }) => {
        const companyQuery = constructQuery(companies, 'companies')
        const ownerQuery = constructQuery(owners, 'owners');
        const industryQuery = constructQuery(industries, 'industries')
        const countryQuery = constructQuery(countries, 'countries')
        return {
          url: `/api/placements?${companyQuery}${ownerQuery}${industryQuery}${countryQuery}`,
        };
      },
      providesTags: ['Placements'],
      transformResponse: (response: { placements: Placement[] }) =>
        response.placements.sort((a, b) => (a.id < b.id ? 1 : -1)),
    }),

    postPlacement: builder.mutation<
      void,
      { body: FormData}
    >({
      query: (params) => {
        return {
          url: `/api/placements`,
          method: 'POST',
          body: params.body,
        };
      },
      invalidatesTags: ['Placements', 'Companies'],
    }),
    editPlacement: builder.mutation<
      void,
      { body: FormData; placementId: number }
    >({
      query: (params) => {
        return {
          url: `/api/placements/${params.placementId}`,
          method: 'PUT',
          body: params.body,
        };
      },
      invalidatesTags: ['Placements'],
    }),
    deletePlacement: builder.mutation<
      void,
      { placementId: number }
    >({
      query: (params) => {
        return {
          url: `/api/placements/${params.placementId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Placements'],
    }),
  }),
});

export const {useGetPlacementsQuery, usePostPlacementMutation, useEditPlacementMutation, useDeletePlacementMutation} = placementsApi;