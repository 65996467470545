import Button from '@mui/material/Button';
import React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import SingleSelect from '../../micro/singleSelect';
import { CompanyType } from '../../../models';
import { useNavigate } from 'react-router-dom';
import { ParsedInternalSearchQuery } from '../../../models/query';
import { useGetCompaniesQuery } from '../../../api/companyEndpoint';
import { Option} from '../../../models';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import MultipleSelect from '../../micro/multipleSelect';

function CustomizedInputBase(props: {
  query?: string;
  companyId: number[] | null;
  type: CompanyType;
  users: Option[];
}) {
  const navigate = useNavigate();

  const [value, setValue] = React.useState<string>(
    props.query ? props.query : ''
  );
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };
  const handleSubmit = () => {
    const company = props.companyId ?? [];
    const users = props.users ? props.users.map((user) => user.id) : [];
    navigate(
      `/contacts?query=${value}&companies[]=${company}&users[]=${users}&type=${props.type}`
    );
  };
  return (
    <Paper
      component="form"
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        maxHeight: '60px',
      }}
      onSubmit={(e: any) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <InputBase
        value={value}
        onChange={handleInputChange}
        sx={{ ml: 1, flex: 1 }}
        placeholder="Enter a contact's name, email, or phone number"
        inputProps={{
          'aria-label': "Enter a contact's name, email, or phone number",
        }}
      />
      <Button
        type="submit"
        sx={{ p: '10px' }}
        aria-label="search"
        variant="contained"
      >
        Search
      </Button>
    </Paper>
  );
}

export const ContactSearchBar = (props: {
  locationProps: ParsedInternalSearchQuery;
  users?: Option[];
}) => {
  const { data: companies } = useGetCompaniesQuery({
    q: '',
  });
  const [selectedCompany, setSelectedCompany] = React.useState<number[] | null>(null);
  const [selectedUsers, setSelectedUsers] = React.useState<Option[]>([]);
  const [selectedType, setSelectedType] = React.useState<CompanyType>('ANY');
  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          maxHeight: '10',
          flexDirection: 'row',
        }}
      >
        <div
          style={{
            margin: '1',
            minWidth: '120',
            maxHeight: '10',
            marginRight: '5',
            display: 'flex',
            flexDirection: 'row',
            padding: '2px 4px',
          }}
        >
          <Autocomplete
            multiple
            filterSelectedOptions
            disablePortal
            id="combo-box-demo"
            limitTags={2}
            options={companies ?? []}
            getOptionLabel={(option) => option.name}
            onChange={(event: any, newValue) => {
              setSelectedCompany(
                newValue ? newValue.map((company) => company.id) : []
              );
            }}
            sx={{ minWidth: 300, maxwidth: 400 }}
            renderInput={(params) => <TextField {...params} label="Company" />}
          />
          <SingleSelect
            error={false}
            setSelected={setSelectedType}
            initialValue={props.locationProps.type as CompanyType}
            label="Type"
            options={['PROSPECT', 'CLIENT', 'ANY']}
          />
          <Autocomplete
            filterSelectedOptions
            disablePortal
            multiple
            id="combo-box-demo"
            options={props.users ?? []}
            value={selectedUsers}
            getOptionLabel={(option) => option.label}
            onChange={(event: any, sUsers) => {
              setSelectedUsers(sUsers);
            }}
            sx={{ width: 200 }}
            renderInput={(params) => (
              <TextField {...params} label="Users" />
            )}
          />
        </div>
        <CustomizedInputBase
          query={props.locationProps['query']}
          companyId={selectedCompany}
          type={selectedType}
          users={selectedUsers}
        />
      </div>
    </>
  );
};
