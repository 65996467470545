import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import { PlacementSearchBar } from '../components/shared/searchbars/placementSearch';
import { PageSkeletton } from '../components/shared/pageSkeletton';
import { useGetPlacementsQuery } from '../api/placementEndpoint';
import { parseSearchQuery } from '../utils/common';
import { useLocation } from 'react-router-dom';
import { Placement } from '../models';
import TablePagination from '@mui/material/TablePagination';
import React from 'react';
import { useGetUsersOptionsQuery } from '../api/usersEndpoint';
import { PlacementRow } from '../components/shared/rows/placementRow';


export function AllPlacementPage() {
  //STATE
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //GETTING QUERY PARAMS
  const location = useLocation();
  const locationProps = parseSearchQuery(location.search.slice(1));

  //GETTING DATA
  const { data: users } = useGetUsersOptionsQuery();
  const {
    data: placements,
    error,
    isFetching,
    refetch,
  } = useGetPlacementsQuery({
    companies: locationProps['companies[]']
      ? locationProps['companies[]'].split(',').map((id) => parseInt(id))
      : [],
    countries: locationProps['countries[]']
      ? locationProps['countries[]'].split(',')
      : [],
    owners: locationProps['users[]']
      ? locationProps['users[]'].split(',').map((id) => parseInt(id))
      : [],
    industries: locationProps['industries[]']
      ? locationProps['industries[]'].split(',').map((id) => parseInt(id))
      : [],
  });
  console.log(placements)
  React.useEffect(() => {
    refetch();
  }, [location]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <PageSkeletton name="All Placements">
      <>
        <PlacementSearchBar locationProps={locationProps} users={users} />
        {isFetching && <LinearProgress />}

        <TableContainer component={Paper} sx={{marginTop:4}}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              {placements &&
                placements.length > 0 &&
                placements
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((placement: Placement) => (
                    <PlacementRow placement={placement} />
                  ))}
              {placements && placements.length === 0 && (
                <TableRow>
                  <TableCell>No result found.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {placements && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={placements.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </>
    </PageSkeletton>
  );
}