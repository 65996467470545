import { Note } from '../models';
import {api} from './api'
const NotesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addNote: builder.mutation<void, Note>({
      query: (note: Note) => {
        return {
          url: `/api/notes`,
          method: 'POST',
          body: note,
        };
      },
      invalidatesTags: ['Companies', 'Contacts'],
    }),
    editNote: builder.mutation<void, { content: string; noteId: number }>({
      query: (params) => {
        return {
          url: `/api/notes/${params.noteId}`,
          method: 'PUT',
          body: { "content": params.content },
        };
      },
      invalidatesTags: ['Companies', 'Contacts'],
    }),
    deleteNote: builder.mutation<void, number>({
      query: (noteId) => {
        return {
          url: `/api/notes/${noteId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Companies', 'Contacts'],
    }),
  }),
});

export const {useAddNoteMutation, useDeleteNoteMutation, useEditNoteMutation} = NotesApi;