import Button from '@mui/material/Button';
import React from 'react';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { ParsedInternalSearchQuery } from '../../../models/query';
import { useGetCompanyOptionsQuery } from '../../../api/companyEndpoint';
import { Option } from '../../../models';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import MultipleSelect from '../../micro/multipleSelect';
import { useGetIndustriesQuery } from '../../../api/industryEndpoint';
import countries from '../../../utils/countries.json';
import { CountryType } from '../../../models';
import { Box } from '@mui/material';

function CustomizedInputBase(props: {
  users: Option[];
  countries: string[];
  companies: Option[];
  industries: Option[];
}) {
  const navigate = useNavigate();

  const handleSubmit = () => {
    const company = props.companies ? props.companies.map((company) => company.id) : [];;
    const users = props.users ? props.users.map((user) => user.id) : [];
    const industry = props.industries ? props.industries.map((industry) => industry.id) : [];
    navigate(
      `/placements?companies[]=${company}&users[]=${users}&industries[]=${industry}&countries[]=${props.countries}`
    );
  };
  return (
    <Paper
      component="form"
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        maxHeight: '60px',
        backgroundColor: '#f5f5f5',
      }}
      elevation={0}
      onSubmit={(e: any) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Button
        type="submit"
        sx={{ p: '10px' }}
        aria-label="search"
        variant="contained"
      >
        Search
      </Button>
    </Paper>
  );
}

export const PlacementSearchBar = (props: {
  locationProps: ParsedInternalSearchQuery;
  users?: Option[];
}) => {
  const { data: companies } = useGetCompanyOptionsQuery();
  const { data: industries } = useGetIndustriesQuery();

  const [selectedCompanies, setSelectedCompanies] = React.useState<Option[]>(
    []
  );
  const [countryValue, setCountryValue] = React.useState<CountryType[]>( props.locationProps['countries[]'] ? countries.countries.filter(
      (country) => props.locationProps['countries[]'].includes(country.label)
    ) : [] );
  const [selectedUsers, setSelectedUsers] = React.useState<Option[]>([]);
  const [selectedCountries, setSelectedCountries] = React.useState<string[]>([]);
  const [selectedIndustries, setSelectedIndustries] = React.useState<Option[]>(
    []
  );

  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          maxHeight: '10',
          flexDirection: 'row',
        }}
      >
        <div
          style={{
            margin: '1',
            minWidth: '120',
            maxHeight: '10',
            marginRight: '5',
            display: 'flex',
            flexDirection: 'row',
            padding: '2px 4px',
          }}
        >
          <Autocomplete
            filterSelectedOptions
            disablePortal
            multiple
            id="combo-box-demo"
            options={props.users ?? []}
            value={selectedUsers}
            getOptionLabel={(option) => option.label}
            onChange={(event: any, sUsers) => {
              setSelectedUsers(sUsers);
            }}
            sx={{ width: 200 }}
            renderInput={(params) => <TextField {...params} label="Users" />}
          />
          <Autocomplete
            filterSelectedOptions
            disablePortal
            multiple
            id="combo-box-demo"
            options={companies ?? []}
            value={selectedCompanies}
            getOptionLabel={(option) => option.label}
            onChange={(event: any, selectedCompanies) => {
              setSelectedCompanies(selectedCompanies);
            }}
            sx={{ width: 200 }}
            renderInput={(params) => <TextField {...params} label="Companies" />}
          />
          <MultipleSelect
            initialValue={props.locationProps['industries[]']}
            options={industries}
            label="Industries"
            setSelected={setSelectedIndustries}
          />
          <Autocomplete
            id="country-select-placement"
            sx={{
              width: 300,
              color: 'red',
            }}
            multiple
            options={countries.countries}
            value={countryValue}
            onChange={(event: any, newValue) => {
              setCountryValue(newValue);
              setSelectedCountries(newValue.map((country) => country.label));
            }}
            autoHighlight
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.label} ({option.code}) +{option.phone}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a country"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
          />
        </div>
        <CustomizedInputBase
          companies={selectedCompanies}
          users={selectedUsers}
          industries={selectedIndustries}
          countries={selectedCountries}
        />
      </div>
    </>
  );
};
