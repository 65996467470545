import { SearchBar } from '../components/shared/searchbars/searchbar';
import { useLocation } from 'react-router-dom';
import { parseSearchQuery } from '../utils/common';
import { CustomizableTable } from "../components/shared/customizableTable"
import { PageSkeletton } from '../components/shared/pageSkeletton';

export function SearchPage() {
  const location = useLocation();
  const locationProps = parseSearchQuery(location.search.slice(1));
  return (
    <PageSkeletton name='Search page'>
      <>
        <SearchBar name={locationProps.name} entity={locationProps.entity} />
        <br></br>
        <CustomizableTable />
      </>
    </PageSkeletton>
  );
}
