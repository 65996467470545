import { Option } from '../models';
import {api} from './api'
const industryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getIndustries: builder.query<Option[], void>({
      query: () => {
        return {
          url: '/api/industries',
        };
      },
      providesTags: ['Industry'],

      transformResponse: (response: { industries: Option[] }) =>
        response.industries.sort((a, b) => (a.id > b.id ? 1 : -1)),
    }),
    editIndustry: builder.mutation<void, Option>({
      query: ({ id, label }) => ({
        url: `api/industries/${id}`,
        method: 'PUT',
        body: { label },
      }),
      invalidatesTags: ['Industry'],
    }),
    addIndustry: builder.mutation<void, string>({
      query: (label: string) => ({
        url: `api/industries`,
        method: 'POST',
        body: { label },
      }),
      invalidatesTags: ['Industry'],
    }),
    deleteIndustry: builder.mutation<void, number>({
      query: (id: number) => ({
        url: `api/industries/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Industry'],
    }),
  }),
});

export const {
  useGetIndustriesQuery,
  useEditIndustryMutation,
  useAddIndustryMutation,
  useDeleteIndustryMutation,
} = industryApi;
