import Button from '@mui/material/Button';
import React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import MultipleSelect from '../../micro/multipleSelect';
import SingleSelect from '../../micro/singleSelect';
import { Option, CompanyType } from '../../../models'
import { useNavigate } from 'react-router-dom';
import { ParsedInternalSearchQuery } from '../../../models/query';
import { Autocomplete, TextField } from '@mui/material';

function CustomizedInputBase(props: { query?: string, industries?: Option[], tags?: Option[], users?: Option[], type: CompanyType }) {
  const navigate = useNavigate();

  const [value, setValue] = React.useState<string>(
    props.query? props.query : ''
  );
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };
  const handleSubmit = () => {
    const industries = props.industries ? props.industries.map(industry => industry.id) : []
    const tags = props.tags ? props.tags.map(tag => tag.id) : []
    const users = props.users ? props.users.map((user) => user.id) : [];
    navigate(`/companies?query=${value}&industries[]=${industries}&tags[]=${tags}&users[]=${users}&type=${props.type}`);


  }
  return (
    <Paper
      component="form"
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        maxHeight: '60px',
      }}
      onSubmit={(e: any) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <InputBase
        value={value}
        onChange={handleInputChange}
        sx={{ ml: 1, flex: 1 }}
        placeholder="Enter a company's name"
        inputProps={{
          'aria-label': "Enter a company's name",
        }}
      />
      <Button
        type="submit"
        sx={{ p: '10px' }}
        aria-label="search"
        variant="contained"
      >
        Search
      </Button>
    </Paper>
  );
}

export const CompanySearchBar = (props: {locationProps:ParsedInternalSearchQuery , industries?: Option[], tags?: Option[], users?: Option[]}) => {
  // Link search entity with options
  const [selectedIndustries, setSelectedIndustries] = React.useState<Option[]>([]);
  const [selectedTags, setSelectedTags] = React.useState<Option[]>(
    []
  );
  const [selectedUsers, setSelectedUsers] = React.useState<Option[]>([]);
  const [selectedType, setSelectedType] = React.useState<CompanyType>("ANY");
  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          maxHeight: '10',
          flexDirection: 'row',
        }}
      >
        <div
          style={{
            margin: '1',
            minWidth: '120',
            maxHeight: '10',
            marginRight: '5',
            display: 'flex',
            flexDirection: 'row',
            padding: '2px 4px',
          }}
        >
          <MultipleSelect
            initialValue={props.locationProps['industries[]']}
            options={props.industries}
            label="Industry"
            setSelected={setSelectedIndustries}
          />
          <MultipleSelect
            initialValue={props.locationProps['tags[]']}
            options={props.tags}
            label="Tags"
            setSelected={setSelectedTags}
          />
          <SingleSelect
            error={false}
            setSelected={setSelectedType}
            initialValue={props.locationProps.type as CompanyType}
            label="Type"
            options={['PROSPECT', 'CLIENT', 'ANY']}
          />
          <Autocomplete
            filterSelectedOptions
            disablePortal
            multiple
            id="combo-box-demo"
            options={props.users ?? []}
            value={selectedUsers}
            getOptionLabel={(option) => option.label}
            onChange={(event: any, sUsers) => {
              setSelectedUsers(sUsers);
            }}
            sx={{ width: 200 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Users"
              />
            )}
          />
        </div>
        <CustomizedInputBase
          query={props.locationProps['query']}
          industries={selectedIndustries}
          tags={selectedTags}
          type={selectedType}
          users={selectedUsers}
        />
      </div>
    </>
  );
};
