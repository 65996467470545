import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function DuplicateModal(props: {
  handleConfirm: any;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const handleClose = () => {
    props.setOpen(false);
  };
  const handleSubmit = async () => {
    await props.handleConfirm();
    handleClose();
  };

  return (
    <>
      <Dialog open={props.open} onClose={handleClose}>
        <DialogTitle>Duplicate detected</DialogTitle>
        <DialogContent sx={{ minWidth: '400px' }}>
          <DialogContentText>
            There is a company with the same name, do you want to proceed with the creation?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
