import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import { ContactSearchBar } from '../components/shared/searchbars/contactSearch';
import { PageSkeletton } from '../components/shared/pageSkeletton';
import { useGetContactsQuery } from '../api/contactEndpoint';
import { parseSearchQuery } from '../utils/common';
import { useLocation, Link } from 'react-router-dom';
import { CompanyType, Contact } from '../models';
import TablePagination from '@mui/material/TablePagination';

import React from 'react';
import { useGetUsersOptionsQuery } from '../api/usersEndpoint';
import { ContactRow } from '../components/shared/rows/contactRow';


export function AllContactPage() {
  //STATE
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //GETTING QUERY PARAMS
  const location = useLocation();
  const locationProps = parseSearchQuery(location.search.slice(1));

  //GETTING DATA
  const { data: users } = useGetUsersOptionsQuery();
  const {
    data: contacts,
    isFetching,
    refetch,
  } = useGetContactsQuery({
    q: locationProps.query,
    type: locationProps.type as CompanyType,
    companies: locationProps['companies[]']
      ? locationProps['companies[]'].split(',').map((id) => parseInt(id))
      : [],
    users: locationProps['users[]']
      ? locationProps['users[]'].split(',').map((id) => parseInt(id))
      : [],
  });


  React.useEffect(() => {
    refetch();
  }, [location]);

  
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <PageSkeletton name="All Contacts">
      <>
        <ContactSearchBar locationProps={locationProps} users={users} />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              {isFetching && <LinearProgress />}
              {contacts &&
                contacts.length > 0 &&
                contacts
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((contact: Contact) => (
                    <ContactRow contact={contact}/>
                  ))}
              {contacts && contacts.length === 0 && (
                <TableRow>
                  <TableCell>No result found.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {contacts && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={contacts.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </>
    </PageSkeletton>
  );
}
