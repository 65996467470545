import React from 'react';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { useLocation, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { parseSearchQuery } from '../utils/common';
import { useDeleteCompanyMutation, useGetCompanyQuery } from '../api/companyEndpoint';
import { PageSkeletton } from '../components/shared/pageSkeletton';
import FileList from '../components/micro/fileList';
import { History } from '../components/shared/history';
import CompanyContacts from '../components/micro/companyContacts';
import CompanyPlacements from '../components/micro/companyPlacements';
import { PlacementModal } from '../components/modals/placementModal';
import { UpdateIndustryModal } from '../components/modals/industryModals';
import { ProfileInfo } from '../components/shared/profileInfo';
import { CompanyDetail, ProfileInfoData} from '../models';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Tooltip from '@mui/material/Tooltip';
import { CompanyModal } from '../components/modals/companyModal';
import { ContactModal } from '../components/modals/contactModal';
import { UploadModal } from '../components/modals/uploadModal';
import { useSelector } from 'react-redux';
import { RootState } from '../models/redux';
import { useDispatch } from 'react-redux';
import { actions as requestActions } from '../redux/slicers/requests';
import FileService from '../api/file';
import { useInvalidateCacheMutation } from '../api/fileEndpoint';
import { UpdateTagsModal } from '../components/modals/tagsModal';
import DeleteModal from '../components/modals/deleteModal';
import DeleteIcon from '@mui/icons-material/Delete';

const fileTypes = ['JPG', 'PNG', 'GIF', 'PDF', 'DOC', 'DOCX', 'JPEG'];

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  elevation: 0,
}));

const FONTSIZE = 16;
const calcWidth = (string: string | null): string => {
  if (string === null) {
    return 'unset';
  }
  return `${string.length * FONTSIZE}px`;
};
const getProfileInfo = (company: CompanyDetail): ProfileInfoData => {
  const type = `${company.type[0]}${company.type.slice(1).toLowerCase()}`
  return {
    type,
    country: company.country,
    address: company.address,
    background: company.background,
  };
};

const historyTitleStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  textAlign: 'left',
  paddingTop: '10px',
  paddingLeft: '10px',
};

const historyTitleContentStyle: React.CSSProperties = {
  marginBottom: '4px',
  marginTop: '0px',
};

const getTags = (company: CompanyDetail | undefined): string | null => {
  if (company) {
    const result = company.tags.reduce((acc, curr) => `${acc}, ${curr.label}`, '').slice(1)
    return result.length > 0 ? result : null;
  }
  return null
}

export function CompanyPage() {
  const [openDelete, setOpenDelete] = React.useState(false);
  const navigate = useNavigate();
  const [deleteCompany] = useDeleteCompanyMutation();
  const location = useLocation();
  const locationProps = parseSearchQuery(location.search.slice(1));
  const [tagsText, setTagsText] = React.useState<string | null>('');
  const { data: company, isFetching } = useGetCompanyQuery(locationProps.id);
  console.log("🚀 ~ file: company.tsx:93 ~ CompanyPage ~ company", company)
  const [industry, setIndustry] = React.useState(
    company?.industry?.label ?? ''
  );
  const [expanded, setExpanded] = React.useState(true);
  const [contactsExpanded, setContactsExpanded] = React.useState(true);
  const [createContact, setCreateContact] = React.useState(false);
  const [addPlacement, setAddPlacement] = React.useState(false);
  const [editTags, setEditTags] = React.useState(false);
  const [editCompany, setEditCompany] = React.useState(false);
  const [openFile, setOpenFile] = React.useState(false);
  // will open a modal to edit the company industry
  const [editIndustry, setEditIndustry] = React.useState(false);
  const isAdmin = useSelector((state: RootState) => state.login.isAdmin);
  const userId = useSelector((state: RootState) => state.login.userId);
  const dispatch = useDispatch();
  const [invalidateCache] = useInvalidateCacheMutation();
  React.useEffect(() => {
    if (company) {
      setIndustry(company.industry.label);
      setTagsText(getTags(company));
    }
  }, [company]);
  const openAddPlacement = () => {
    setAddPlacement(true);
  };
  const openCreateContact = () => {
    setCreateContact(true);
  };
  const handleFailure = (e: any) => {
    console.log(e);
    dispatch(requestActions.requestFailure('Something went wrong.'));
  };
  const handleDelete = async () => {
    if (company) {
      const resp = await deleteCompany(company.id);
      if ('error' in resp) {
        dispatch(requestActions.requestFailure('Something went wrong'));
      } else {
        dispatch(requestActions.requestSuccess());
        navigate('/companies');
      }
    } else {
      dispatch(requestActions.requestFailure('Something went wrong'));
    }
  };
  const hasRights = (ownerId: number) =>
    ownerId === parseInt(userId) || isAdmin;
  const handleUpload = async (
    files: FileList | null,
    setProgress: React.Dispatch<React.SetStateAction<number>>,
    setUploadStarted: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (files !== null && company) {
      setUploadStarted(true);
      const filesArray = Array.from(files);
      try {
        filesArray.forEach(async (file) => {
          try {
            const response = await FileService.upload(
              file,
              company.id,
              'company',
              (event: any) => {
                setProgress(Math.round((100 * event.loaded) / event.total));
              }
            );
            setUploadStarted(false);
            dispatch(requestActions.requestSuccess());
            invalidateCache();
            setOpenFile(false);
          } catch (e) {
            handleFailure(e);
          }
        });
      } catch (e) {
        handleFailure(e);
      }
    }
  };
  return (
    <PageSkeletton name={`${company?.name ?? ''} Company Page`}>
      <>
        {isAdmin && (
          <DeleteModal
            open={openDelete}
            setOpen={setOpenDelete}
            handleConfirm={handleDelete}
          />
        )}
        {company && (
          <PlacementModal
            type="add"
            open={addPlacement}
            setOpen={setAddPlacement}
            companyId={company?.id}
          />
        )}
        {company && (
          <UpdateIndustryModal
            open={editIndustry}
            setOpen={setEditIndustry}
            currentIndustry={company.industry}
            companyId={company.id}
          />
        )}
        {company && (
          <UpdateTagsModal
            open={editTags}
            setOpen={setEditTags}
            currentTags={company.tags}
            companyId={company.id}
          />
        )}
        {company && (
          <CompanyModal
            open={editCompany}
            setOpen={setEditCompany}
            type="edit"
            editData={company}
          />
        )}
        {company && (
          <ContactModal
            open={createContact}
            setOpen={setCreateContact}
            type="addPrefill"
            editData={{ company: { id: company.id, label: company.name } }}
          />
        )}

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <FormControl variant="standard" style={{ marginRight: '10px' }}>
            <InputLabel htmlFor="input-with-icon-adornment">
              Industry
            </InputLabel>
            <Input
              id="input-with-icon-adornment"
              style={{
                width: calcWidth(industry),
              }}
              value={industry}
              className="industryLabel"
              disabled
              endAdornment={
                <IconButton
                  onClick={() => {
                    setEditIndustry(true);
                  }}
                >
                  <EditIcon />
                </IconButton>
              }
            />
          </FormControl>

          <FormControl
            key={new Date().getTime()}
            variant="standard"
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <InputLabel htmlFor="input-with-icon-adornment">Tags</InputLabel>
            <Input
              id="input-with-icon-adornment"
              style={{
                width: calcWidth(tagsText),
              }}
              value={tagsText}
              className="industryLabel"
              disabled
              endAdornment={
                <IconButton
                  onClick={() => {
                    setEditTags(true);
                  }}
                >
                  <EditIcon />
                </IconButton>
              }
            />
          </FormControl>
          {isAdmin ? (
            <div style={{ marginLeft: '10px', marginTop: "20px"}}>
              <Tooltip title="Delete this company">
                <IconButton
                  size="small"
                  onClick={() => {
                    setOpenDelete(true);
                  }}
                >
                  <DeleteIcon color='error'/>
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            <></>
          )}
        </div>
        <Grid container spacing={2}>
          <Grid item md={9}>
            <Item style={{ display: 'flex', flexDirection: 'row' }}>
              <Grid md={6} item>
                <Button style={{ float: 'right' }} onClick={openCreateContact}>
                  Create contact
                </Button>
              </Grid>
              <Grid md={3} item>
                <Button style={{ float: 'right' }} onClick={openAddPlacement}>
                  Add a placement
                </Button>
              </Grid>
              <Grid md={3} item>
                <Button onClick={() => setOpenFile(true)}>Attach a file</Button>
              </Grid>
            </Item>
            <UploadModal
              allowed={fileTypes}
              open={openFile}
              setOpen={setOpenFile}
              handleUpload={handleUpload}
            />
            <Item>{company && <FileList files={company.files} />}</Item>
            <Accordion
              expanded={expanded}
              onChange={() => {
                setExpanded(!expanded);
              }}
              disableGutters
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div style={historyTitleStyle}>
                  <h1 style={historyTitleContentStyle}>History</h1>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {company && (
                  <>
                    <History
                      history={company.historyData}
                      companyId={parseInt(locationProps.id)}
                      isFetching={isFetching}
                    />
                  </>
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion
              disableGutters
              expanded={contactsExpanded}
              onChange={() => {
                setContactsExpanded(!contactsExpanded);
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div style={historyTitleStyle}>
                  <h1 style={historyTitleContentStyle}>Contacts</h1>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {company && (
                  <CompanyContacts
                    name={company.name}
                    contacts={company.contacts}
                    isFetching={isFetching}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item md={3}>
            <div style={historyTitleStyle}>
              <h3 style={historyTitleContentStyle}>Company info</h3>
              {company && hasRights(company.ownerId) && (
                <Tooltip title="Edit">
                  <IconButton
                    onClick={() => setEditCompany(true)}
                    style={{ position: 'relative', bottom: '5px' }}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </div>
            <Item>
              {company && (
                <ProfileInfo
                  data={getProfileInfo(company)}
                  isFetching={isFetching}
                />
              )}
            </Item>
            <div style={historyTitleStyle}>
              <h3 style={historyTitleContentStyle}>Placements</h3>
            </div>
            <Item>
              <CompanyPlacements
                companyId={parseInt(locationProps.id)}
                companyName={company?.name ?? ''}
                isFetching={isFetching}
              />
            </Item>
          </Grid>
        </Grid>
      </>
    </PageSkeletton>
  );
}
