import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useSelector } from 'react-redux';
import { RootState } from '../../models/redux';
import { useDispatch } from 'react-redux';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { FileType } from '../../models';
import FileService from '../../api/file';
import DeleteModal from '../modals/deleteModal';
import { useInvalidateCacheMutation } from '../../api/fileEndpoint';
const historyTitleStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  textAlign: 'left',
  paddingTop: '10px',
  paddingLeft: '10px',
};

const historyTitleContentStyle: React.CSSProperties = {
  marginBottom: '4px',
  marginTop: '0px',
};

const historyDescriptionStyle: React.CSSProperties = {
  marginBottom: '4px',
  marginTop: '14px',
  marginLeft: '60px'
};

const fileContentContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  marginLeft: '3rem',
  padding: '5px 8px 5px 8px',
  marginBottom: '5px',
  backgroundColor: '#e8f4f4',
  width: 'fit-content',
  borderRadius: '25px',
};

const imgStyle: React.CSSProperties = {
  marginRight: '8px',
};


const iconMapping = {
  'image/png': <ImageIcon color="primary" style={imgStyle} fontSize="small" />,
  'application/pdf': (
    <PictureAsPdfIcon color="error" style={imgStyle} fontSize="small" />
  ),
};
export default function FileList({ files }: { files: FileType[] }) {
  const [openDelete, setOpenDelete] = React.useState(false);
  const [expanded, setExpanded] = React.useState(true);
  const [invalidateCache] = useInvalidateCacheMutation();
  const [focusedFile, setFocusedFile] = React.useState<string>('');
  const isAdmin = useSelector((state: RootState) => state.login.isAdmin);
  const userId = useSelector((state: RootState) => state.login.userId);
  const hasRights = (ownerId: number) =>
    ownerId === parseInt(userId) || isAdmin;
  const handleDownload = async (id: string, name:string) => {
    await FileService.getFile(id, name);
  };
  const handleDelete = async () => {
    await FileService.deleteFile(focusedFile);
    invalidateCache();
  };
  const getDescription = (files: FileType[]) => files.length === 0 ? "No file uploaded" : `Files (${files.length})`
  return (
    <Accordion
      expanded={expanded}
      onChange={() => {
        setExpanded(!expanded);
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div style={historyTitleStyle}>
          <h1 style={historyTitleContentStyle}>Files</h1>
          <h5 style={historyDescriptionStyle}> {getDescription(files)}</h5>
        </div>
      </AccordionSummary>
      <DeleteModal
        open={openDelete}
        setOpen={setOpenDelete}
        handleConfirm={handleDelete}
      />
      <AccordionDetails>
        {files.map((file) => {
          return (
            <div key={file.id} style={fileContentContainerStyle}>
              {iconMapping[file.mimetype as 'application/pdf' | 'image/png']}
              <Typography>{file.name}</Typography>
              <IconButton
                size="small"
                onClick={() => {
                  handleDownload(file.id, file.name);
                }}
              >
                <Tooltip title="Download">
                  <FileDownloadIcon fontSize="inherit" />
                </Tooltip>
              </IconButton>
              {hasRights(file.ownerId) && (
                <IconButton
                  size="small"
                  onClick={() => {
                    setFocusedFile(file.id);
                    setOpenDelete(true);
                  }}
                >
                  <Tooltip title="Delete">
                    <DeleteForeverIcon fontSize="inherit" />
                  </Tooltip>
                </IconButton>
              )}
            </div>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
}
