import axios from 'axios';
const token = localStorage.getItem('naosUserToken');
const http = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    'Content-type': 'application/json',
    authorization: `${localStorage.getItem('naosUserToken')}`,
  },
});
class FilesService {
  typeMapping: { [key: string]: string };
  constructor() {
    this.typeMapping = {
      contact: 'contactId',
      company: 'companyId',
    };
  }
  upload(file: File, id: number, type: string, onUploadProgress: any) {
    let formData = new FormData();
    formData.append('upload', file);
    formData.append(this.typeMapping[type], id.toString());
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    if (token === null) {
      Object.assign(http.defaults, {
        headers: { authorization: localStorage.getItem('naosUserToken') },
      });
    }
    return http.post('/api/files', formData, {
      headers: headers,
      onUploadProgress,
    });
  }
  getFile(id: string, name: string) {
    return http
      .get(`/api/files/${id}`, {
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  }
  deleteFile(id: string) {
    return http.delete(`/api/files/${id}`);
  }
  importCsv(file: File, onUploadProgress: any, id?: number) {
    let formData = new FormData();
    formData.append('upload', file);
    if (id) {
      formData.append('userId', id.toString());
    }
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    if (token === null) {
      Object.assign(http.defaults, {
        headers: { authorization: localStorage.getItem('naosUserToken') },
      });
    }
    return http.post('/api/import-export', formData, {
      headers: headers,
      onUploadProgress,
    });
  }
  exportCsv() {
    if (token === null) {
      Object.assign(http.defaults, {
        headers: { authorization: localStorage.getItem('naosUserToken') },
      });
    }
    return http
      .get(`/api/import-export`, {
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'snapshot.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  }
}
export default new FilesService();
