import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { PageSkeletton } from '../components/shared/pageSkeletton';
import { useGetPlacementsQuery } from '../api/placementEndpoint';
import { decodeString, parseSearchQuery } from '../utils/common';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../models/redux';
import { Placement } from '../models';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import {PlacementModal} from '../components/modals/placementModal';
import DeleteModal from '../components/modals/deleteModal';
import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useDeletePlacementMutation } from '../api/placementEndpoint';
import { useDispatch } from 'react-redux';
import { actions as requestActions } from '../redux/slicers/requests';
import FileService from '../api/file';
import { Button } from '@mui/material';

const TEXTSIZE = `${window.innerWidth * 0.1}px`;

export function PlacementPage() {
  //GETTING QUERY PARAMS
  const location = useLocation();
  const locationProps = parseSearchQuery(location.search.slice(1));
  //REDUX STATE
  const isAdmin = useSelector((state: RootState) => state.login.isAdmin);
  const userId = useSelector((state: RootState) => state.login.userId);
  const [deletePlacement] = useDeletePlacementMutation();
  const dispatch = useDispatch();
  //STATE
  const [openEdit, setOpenEdit] = React.useState(false);
  const [change, setChange] = React.useState(false);
  const [focusedPlacement, setFocusedPlacement] = React.useState<Placement | null>(null);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [deleteChange, setDeleteChange] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  //GETTING DATA
  const { data: placements, refetch } = useGetPlacementsQuery({ companies: [parseInt(locationProps.company)] });
  //HANDLERS
  React.useEffect(() => {
    refetch();
  }, []);
  React.useEffect(() => {
    if (change){
      setOpenEdit(true);
      setChange(false);
    }
  }, [change]);
  React.useEffect(() => {
    if (deleteChange) {
      setOpenDelete(true);
      setDeleteChange(false);
    }
  }, [deleteChange]);

  const handleDelete = async () => {
      const resp = await deletePlacement({
        placementId: focusedPlacement !== null ? focusedPlacement.id : -1,
      });
    if ('error' in resp) {
      dispatch(requestActions.requestFailure('Something went wrong.'))
      console.log(resp['error'])
    }
    else {
      dispatch(requestActions.requestSuccess());
    }
  }
   const handleDownload = async (id: string, name: string) => {
     await FileService.getFile(id, name);
  };
  
  const hasRights = (ownerId: number) => ownerId === parseInt(userId) || isAdmin;
  return (
    <PageSkeletton name="Placements">
      <>
        <PlacementModal
          type="add"
          companyId={parseInt(locationProps.company)}
          open={openAdd}
          setOpen={setOpenAdd}
        />
        {focusedPlacement !== null && (
          <>
            <PlacementModal
              type="edit"
              companyId={parseInt(locationProps.company)}
              open={openEdit}
              setOpen={setOpenEdit}
              editData={focusedPlacement}
            />
            <DeleteModal
              open={openDelete}
              setOpen={setOpenDelete}
              handleConfirm={handleDelete}
            />
          </>
        )}
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
          }}
        >
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h4"
            id="tableTitle"
            component="div"
          >
            Placements at{' '}
            <a
              href={`/company?n=${locationProps.n}&id=${locationProps.company}`}
            >
              {locationProps.n && `${decodeString(locationProps.n)}`}
            </a>
          </Typography>
          {locationProps.company && (
            <Tooltip title="Add placement">
              <IconButton
                onClick={() => {
                  setOpenAdd(true);
                }}
              >
                <AddCircleIcon />
              </IconButton>
            </Tooltip>
          )}
        </Toolbar>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650, overflowX: 'hidden' }}
            aria-label="simple table"
          >
            <TableBody>
              {placements &&
                placements.map((placement) => (
                  <TableRow key={placement.id}>
                    <TableCell scope="row">
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div
                          style={{
                            fontWeight: 'bold',
                            fontSize: '16px',
                          }}
                        >
                          {placement.title}
                        </div>
                        <div style={{ fontWeight: 'bold', color: 'grey' }}>
                          <span>{placement.signatureDate} </span>
                          <span>{placement.country}</span>
                        </div>
                        <div style={{ fontWeight: 'bold', color: 'grey' }}>
                          {placement.candidateFullname}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        maxWidth: '400px',
                        color: 'grey',
                        textAlign: 'left',
                      }}
                      align="left"
                      scope="row"
                    >
                      <TableCell>
                        <Typography
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'left',
                          }}
                        >
                          {placement.background}
                        </Typography>
                        {placement.attachement && (
                          <div
                            style={{
                              textAlign: 'left',
                              color: '#358DD6',
                              cursor: 'pointer',
                              border: 0,
                            }}
                            onClick={() => {
                              handleDownload(
                                placement.attachement.id,
                                placement.attachement.name
                              );
                            }}
                          >
                            <span
                              style={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                width: TEXTSIZE,
                                textAlign: 'left',
                              }}
                            >
                              {placement.attachement.name}
                            </span>
                          </div>
                        )}
                      </TableCell>
                    </TableCell>
                    {hasRights(placement.ownerId) && (
                      <TableCell style={{ width: '50px' }}>
                        <IconButton
                          onClick={() => {
                            setFocusedPlacement(placement);
                            setChange(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setFocusedPlacement(placement);
                            setDeleteChange(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </PageSkeletton>
  );
}
