import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../models/redux";
import { useDispatch } from 'react-redux';
import { actions as loginActions } from '../redux/slicers/users';
import axios from 'axios';
import { logout } from "../api/auth";


export function RequireAuth({ children }: { children: JSX.Element }) {
  const dispatch = useDispatch();
  const token = localStorage.naosUserToken;
  const emptyStore= useSelector((state: RootState) => state.login.userId === '');
  if (token && emptyStore) {
    try {
      dispatch(loginActions.loginSuccess(token));
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/users/me`, {
          headers: { authorization: `${token}` },
        })
        .then((res) => {
          dispatch(loginActions.userInfoSuccess(res.data.profile));
        }).catch((e) => { 
          dispatch(loginActions.loginError(e.message));
          logout();
        });
    } catch (e: any) {
      dispatch(loginActions.loginError(e.message));
      logout();
     }
  }
let isAuth = useSelector((state: RootState) => { return state.login.token !== '' })
let location = useLocation();

  if (!isAuth) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export function RequireAdmin({ children }: { children: JSX.Element }) {
  let location = useLocation();
  const dispatch = useDispatch();
  const token = localStorage.naosUserToken;
  const emptyStore = useSelector(
    (state: RootState) => state.login.userId === ''
  );
  let isAdmin = useSelector((state: RootState) => {
    return state.login.isAdmin;
  })
  let isAuth = useSelector((state: RootState) => {
    return state.login.token !== ''
  })
  if (token && emptyStore) {
    try {
      dispatch(loginActions.loginSuccess(token));
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/users/me`, {
          headers: { authorization: `${token}` },
        })
        .then((res) => {
          dispatch(loginActions.userInfoSuccess(res.data.profile));
          isAdmin = res.data.profile.isAdmin;
          isAuth = res.data.profile.isAuth;
          if (!isAuth || !isAdmin) {
            return (
              <Navigate to="/dashboard" state={{ from: location }} replace />
            );
          }
        })
        .catch((e) => {
          dispatch(loginActions.loginError(e.message));
          logout();
        });
    } catch (e: any) {
      dispatch(loginActions.loginError(e.message));
      logout();
    }
  }
  else if (token && isAdmin) {
    return children;
  }
  else {
    return <Navigate to="/dashboard" />;
  }
  return children;
}

export function RequireNoAuth({ children }: { children: JSX.Element }) {
  let isNotAuth = useSelector((state: RootState) => {
    return state.login.token === '';
  });
  if (!isNotAuth) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/dashboard" />;
  }

  return children;
}