import { Option } from '../../models';
import React from 'react';
import Modal from '@mui/material/Modal';
import { useGetTagsQuery, usePostTagMutation } from '../../api/tagsEndpoint';
import {useEditCompanyTagsMutation} from '../../api/companyEndpoint';
import { useDispatch } from 'react-redux';
import { actions as requestActions } from '../../redux/slicers/requests';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CreatableSelect from 'react-select/creatable';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};



type option = { id: number, value: string, label: string }
const tagToOption = (tag: Option): option => ({
    id: tag.id,
    value: tag.label,
    label: tag.label
})
export const UpdateTagsModal = (props: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentTags: Option[];
  companyId: number;
}) => {
  const [tags, setTags] = React.useState<option[]>(props.currentTags.map(tagToOption));
  const [postTag] = usePostTagMutation();
  const dispatch = useDispatch();
  const { data: tagOptions } = useGetTagsQuery();

  const handleClose = () => {
    setTags(props.currentTags.map(tagToOption));
    props.setOpen(false);
  };
  const [editCompanyTags] = useEditCompanyTagsMutation();
    const handleTagsChange = (tagsArray: readonly Option[]) => {
      console.log('tagsArray', tagsArray);
    setTags([...tagsArray.map(tagToOption)]);
    };
    React.useEffect(() => {
        setTags(props.currentTags.map(tagToOption));
    }, [props.currentTags]);
  const handleTagsCreate = async (inputValue: string) => {
    const resp = await postTag(inputValue);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const resp = await editCompanyTags({
      companyId: props.companyId,
      tags: tags.map((tag: Option) => tag.id),
    });
    if ('error' in resp) {
      dispatch(requestActions.requestFailure('Something went wrong'));
    } else {
      dispatch(requestActions.requestSuccess());
      handleClose();
    }
  };
  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              marginTop: '9px',
            }}
            onSubmit={handleSubmit}
          >
            <Typography variant="h6">Update tags</Typography>
            <br></br>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {tagOptions && (
                <CreatableSelect
                  styles={{
                    menu: (provided) => ({
                      backgroundColor: 'white',
                      position: 'fixed',
                      display: 'block',
                      width: '300px',
                      overflow: 'auto',
                      zIndex: '5000',
                    }),
                  }}
                  isMulti
                  placeholder="No tags"
                  onChange={handleTagsChange}
                  options={tagOptions}
                  value={tags}
                  onCreateOption={handleTagsCreate}
                />
              )}
              <Button
                type="submit"
                sx={{ p: '6px', marginLeft: '25px', mb: 5 }}
                aria-label="update"
                variant="contained"
              >
                Update
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </>
  );
};
