import React, { useEffect } from 'react';
import { SearchBar } from '../components/shared/searchbars/searchbar';
import { PageSkeletton } from '../components/shared/pageSkeletton';
import { useGetRecordsQuery } from '../api/dashboardEndpoint';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import { DashboardRecord } from '../models';
import { DashboardRow } from '../components/shared/rows/dashboardRow';
import { Typography } from '@mui/material';



export default function Dashboard() {
  const { data: records, isFetching, refetch } = useGetRecordsQuery();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
  };
  
  useEffect(() => {
    refetch()
  },[])
  return (
    <PageSkeletton name="Dashboard">
      <>
        <SearchBar />
        <Typography
          sx={{
            marginLeft:1,
            marginTop: 3,
            fontWeight: 'bold',
          }}
          variant="body1"
        >
          Welcome back, here is what's new:
        </Typography>
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              {isFetching && <LinearProgress />}
              {records &&
                records.records.length > 0 &&
                records.records
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((record: DashboardRecord) => (
                    <DashboardRow record={record} />
                  ))}
              {records && records.records.length === 0 && (
                <TableRow>
                  <TableCell>No result found.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {records && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={records.records.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </>
    </PageSkeletton>
  );
}
