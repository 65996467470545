import React from 'react';
import { ParsedInternalSearchQuery } from '../models/query';

export const handleChange = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  hook: React.Dispatch<React.SetStateAction<string>>
) => {
  hook(event.target.value);
};


export const parseSearchQuery = (searchQuery: string) => {
  const query = searchQuery.split('&');
  const parsedQuery: ParsedInternalSearchQuery = {};
  query.forEach((item) => {
    const queryItem = item.split('=');
    const key: string = queryItem[0];
    const value: string = queryItem[1];
    parsedQuery[key] = value;
  });
  return parsedQuery;
}

export const cleanObject = (obj: any) => {
  const copy = JSON.parse(JSON.stringify(obj))
  Object.keys(copy).forEach((key) => {
    if (
      copy[key] === undefined ||
      copy[key] === null ||
      copy[key] === '' ||
      copy[key] === -1
    ) {
      delete copy[key];
    }
  });
  return copy;
}

export const formatPhone = (phone: string | null) => {
  if (phone === null) {
    return '';
  }
  return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
};

// transform search query to readable format (for example "Company%20with%20tag" => "Company with tag")
export const decodeString = (str: string) => {
  return decodeURI(str);
}
//clean string and lowercase
const cleanString = (str: string) => {
  return str.toLowerCase().replaceAll("%20", " ")
}

export const hasDuplicateCompany = (companies: any[], company: any) => {
  //TODO: check if company is already in companies
  const reference = cleanString(company.name);
  return companies.some((c) => {
    const contender = cleanString(c.name)
    return contender === reference
  });
}

export const constructQuery = (filters: any[] | undefined, name: string) => {
  return filters
    ? filters.reduce(
        (prevQuery, filter) => prevQuery + `&${name}[]=${filter}`,
        ''
      )
    : '';
}

export function getFormData(object: Record<string,any>) {
  const formData = new FormData();
  Object.keys(object).forEach((key) => object[key] === '' ? undefined : formData.append(key, object[key]));
  return formData;
}


export const getNameFromEmail = (email: string): string => {
  return email.split('@')[0];
};

export const formatDate = (date: Date) => {
  // YYYY-MM-DD
  const dateString = date.toLocaleDateString('en-CA')

  return dateString;
}
