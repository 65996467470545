import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import React, { useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SingleSelect from '../micro/singleSelect';
import { useGetUsersQuery } from '../../api/usersEndpoint';
import { useSelector } from 'react-redux';
import { RootState } from '../../models/redux';
import { useDispatch } from 'react-redux';
import { actions as requestActions } from '../../redux/slicers/requests';

export function UploadModal(props: {
  open: boolean;
  setOpen: any;
  allowed: string[];
  handleUpload: (
    files: FileList | null,
    setProgress: React.Dispatch<React.SetStateAction<number>>,
    setUploadStarted: React.Dispatch<React.SetStateAction<boolean>>,
    id?: number
  ) => Promise<void>;
  canSelectUser?: boolean;
}) {
  const { data: users } = useGetUsersQuery();
  const currentUser = useSelector((state: RootState) =>
    parseInt(state.login.userId)
  );
  const dispatch = useDispatch();
  const [files, setFiles] = useState<FileList | null>(null);
  const [progress, setProgress] = React.useState(0);
  const [uploadStarted, setUploadStarted] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(currentUser);

  useEffect(() => {
    if (currentUser) {
      setSelectedUser(currentUser);
    }
  }, [currentUser]);

  const handleReset = () => {
    props.setOpen(false);
    setFiles(null);
    setProgress(0);
    setUploadStarted(false);
    setSelectedUser(currentUser);
  }
  const handleUpload = async () => {
    try {
      if (props.canSelectUser) {
        await props.handleUpload(
          files,
          setProgress,
          setUploadStarted,
          selectedUser
        );
      } else {
        await props.handleUpload(files, setProgress, setUploadStarted);
      }
      dispatch(requestActions.requestSuccess());
      handleReset();
    } catch (e) {
      console.log(e);
      dispatch(requestActions.requestFailure('Something went wrong.'));
    }

  };
  const handleChange = (files: FileList) => {
    setFiles(files);
  };
  const handleClose = () => {
    props.setOpen(false);
  };

  const handleSingleSelect = (email: string) => {
    const user = users?.find((user) => user.email === email);
    if (user) {
      setSelectedUser(user?.id);
    }
  };
  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle>Upload your files</DialogTitle>
      {users && props.canSelectUser && (
        <div
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <SingleSelect
            required={true}
            error={false}
            label="Select User"
            options={users.map((u) => u.email) ?? []}
            initialValue={users.find((user) => user?.id === currentUser)?.email}
            setSelected={handleSingleSelect}
          />
        </div>
      )}
      <DialogContent>
        {uploadStarted && (
          <Box className="mb25" display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
            <Box minWidth={35}>
              <Typography
                variant="body2"
                color="textSecondary"
              >{`${progress}%`}</Typography>
            </Box>
          </Box>
        )}
        <FileUploader
          style={{
            height: '400px',
          }}
          multiple={true}
          handleChange={handleChange}
          name="file"
          types={props.allowed}
        />
        <>
          {files !== null &&
            files.length > 0 &&
            Array.from(files).map((file) => {
              return <p>File name: {file.name}</p>;
            })}
          {files === null && <p>No files uploaded yet.</p>}
        </>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleUpload} autoFocus>
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}
