import React, { useEffect } from 'react';
import { requiredValidation } from '../../utils/validation';
import { requiredValidationMessages } from '../../models/validation';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import { cleanObject } from '../../utils/common';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';

import { useGetCompanyOptionsQuery } from '../../api/companyEndpoint';
import {
  useAddContactMutation,
  useEditContactMutation,
} from '../../api/contactEndpoint';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import countries from '../../utils/countries.json';
import { ContactDetail, CompanyType, CountryType } from '../../models';
import { useDispatch } from 'react-redux';
import { actions as requestActions } from '../../redux/slicers/requests';
import _ from 'lodash';
import { IconButton } from '@mui/material';
import { CompanyModal } from './companyModal';
const content = {
  title: {
    add: 'Add a contact',
    edit: 'Edit Contact',
    addPrefill: 'Add a contact',
  },
  button: {
    add: 'Add contact',
    edit: 'Edit Contact',
    addPrefill: 'Add a contact',
  },
};

interface Errors {
  firstname: string;
  lastname: string;
  companyId: string;
  email: string;
  phone: string | null;
  jobTitle: string;
  address: string;
  country: string;
  linkedin: string;
}

const initialState = {
  firstname: '',
  lastname: '',
  email: '',
  company: {id: -1, label: ""},
  phone: '',
  jobTitle: '',
  address: '',
  country: '',
  linkedin: '',
  type: 'PROSPECT' as CompanyType,
};

const validState = {
  lastname: requiredValidationMessages.VALID,
  firstname: requiredValidationMessages.VALID,
  email: requiredValidationMessages.VALID,
  companyId: requiredValidationMessages.VALID,
  phone: requiredValidationMessages.VALID,
  jobTitle: requiredValidationMessages.VALID,
  address: requiredValidationMessages.VALID,
  country: requiredValidationMessages.VALID,
  linkedin: requiredValidationMessages.VALID,
};

export const ContactModal = (props: {
  editData?: Partial<ContactDetail>;
  type: 'add' | 'edit' | 'addPrefill';
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [countryValue, setCountryValue] = React.useState<CountryType | null>(
    countries.countries.find(
      (country) => country.label === props.editData?.country
    ) ?? null
  );
  const { data: companies } = useGetCompanyOptionsQuery();
  const [firstname, setFirstName] = React.useState(initialState.firstname);
  const [lastname, setLastName] = React.useState(initialState.lastname);
  const [email, setEmail] = React.useState<string>(initialState.email);
  const [company, setCompany] = React.useState(initialState.company);
  const [phone, setPhone] = React.useState(initialState.phone);
  const [jobTitle, setJobTitle] = React.useState(initialState.jobTitle);
  const [address, setAddress] = React.useState(initialState.address);
  const [country, setCountry] = React.useState(initialState.country);
  const [linkedin, setLinkedin] = React.useState(initialState.linkedin);
  const [type, setType] = React.useState(initialState.type);
  // errors hook to store errors
  const [errors, setErrors] = React.useState<Errors>(validState);
  const [addCompany, setAddCompany] = React.useState(false);
  const [addContact] = useAddContactMutation();
  const [editContact] = useEditContactMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const prefill = (contact: ContactDetail | typeof initialState) => {
    const country =
      countries.countries.find((country) => country.label === contact.country)
        ?.label ?? '';
    setFirstName(contact.firstname);
    setLastName(contact.lastname);
    setEmail(contact.email ?? '');
    setCompany(contact.company);
    setPhone(contact.phone ?? '');
    setJobTitle(contact.jobTitle);
    setAddress(contact.address ?? '');
    setCountry(country);
    setLinkedin(contact.linkedin ?? '');
    setType(contact.type);
  };
  const concatenate = () => {
    return {
      firstname,
      lastname,
      email,
      companyId: company.id,
      phone,
      jobTitle,
      address,
      country,
      linkedin,
      type,
    };
  };
  useEffect(() => {
    if (props.editData && props.type === 'edit') {
      prefill(props.editData as ContactDetail);
      const country =
        countries.countries.find(
          (country) => country.label === props.editData?.country
        ) ?? null;
      setCountryValue(country);
    } else if (props.type === 'addPrefill' && props.editData && props.editData.company) {
      setCompany(props.editData.company);
    }
  }, [props]);

  const handleAddCompany = () => {
    setAddCompany(true);
  };
  const handleClose = () => {
    props.setOpen(false);
    //reset all fields
    prefill(initialState);
    setCountryValue(null);
  };

  const handleSubmit = async (e: any) => {
    //VALIDATE e.target.value is not empty
    e.preventDefault();
    const validationErrors = {
      firstname: requiredValidation(firstname),
      lastname: requiredValidation(lastname),
      jobTitle: requiredValidation(jobTitle),
      companyId: requiredValidation(company.id),
    };
    const validState = {
      firstname: requiredValidationMessages.VALID,
      lastname: requiredValidationMessages.VALID,
      jobTitle: requiredValidationMessages.VALID,
      companyId: requiredValidationMessages.VALID,
    };
    const valid = _.isEqual(validationErrors, validState);
    var resp:any;
    if (valid) {
      const postBody = cleanObject(concatenate()); // free of empty strings
      if (props.type === 'add' || props.type === 'addPrefill') {
        resp = await addContact(postBody);
      }
      if (props.type === 'edit' && props.editData) {
        resp = await editContact({
          body: postBody,
          contactId: props.editData.id as number,
        });
      }
      if (resp && 'error' in resp) {
        dispatch(requestActions.requestFailure(resp.error.data.message));
        console.log(resp['error']);
      } else {
        dispatch(requestActions.requestSuccess());
        handleClose();
        if (props.type === 'add' || props.type === 'addPrefill') {
          console.log(resp)
          navigate(`/contact?id=${resp.data.contactId}`);
        }
      }
    }
    setErrors({
      ...errors,
      firstname: requiredValidation(firstname),
      lastname: requiredValidation(lastname),
      jobTitle: requiredValidation(jobTitle),
      companyId: requiredValidation(company.id),
    });
  };

  const handleChange =
    (prop: React.Dispatch<React.SetStateAction<any>>) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      prop(event.target.value);
    };

  //Create modal to add new company to database
  return (
    <>
      <CompanyModal type="add" open={addCompany} setOpen={setAddCompany} />
      <Dialog open={props.open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>{content.title[props.type]}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item md={6}>
              <TextField
                autoFocus
                required
                sx={{ width: 300 }}
                margin="dense"
                id="firstname"
                label="First name"
                value={firstname}
                onChange={handleChange(setFirstName)}
                fullWidth
                variant="outlined"
                error={errors.firstname !== requiredValidationMessages.VALID}
                helperText={
                  errors.firstname !== requiredValidationMessages.VALID
                    ? errors.firstname
                    : ''
                }
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                sx={{ width: 300 }}
                autoFocus
                required
                margin="dense"
                id="lastname"
                label="Last name"
                value={lastname}
                onChange={handleChange(setLastName)}
                fullWidth
                variant="outlined"
                error={errors.lastname !== requiredValidationMessages.VALID}
                helperText={
                  errors.lastname !== requiredValidationMessages.VALID
                    ? errors.lastname
                    : ''
                }
              />
            </Grid>

            <Grid item md={6}>
              <TextField
                id="email"
                label="Email"
                variant="outlined"
                sx={{ width: 300 }}
                value={email}
                onChange={handleChange(setEmail)}
                error={errors.email !== requiredValidationMessages.VALID}
                helperText={
                  errors.email !== requiredValidationMessages.VALID
                    ? errors.email
                    : ''
                }
              />
            </Grid>
            <Grid item md={6}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Autocomplete
                  filterSelectedOptions
                  disablePortal
                  id="combo-box-demo"
                  options={companies ?? []}
                  value={company}
                  getOptionLabel={(option) => option.label}
                  onChange={(event: any, newValue) => {
                    setCompany(newValue ? newValue : initialState.company);
                  }}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="Company"
                      error={
                        errors.companyId !== requiredValidationMessages.VALID
                      }
                      helperText={
                        errors.companyId !== requiredValidationMessages.VALID
                          ? errors.companyId
                          : ''
                      }
                    />
                  )}
                />
                <Tooltip title="Add company">
                  <IconButton onClick={handleAddCompany}>
                    <AddIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </div>
            </Grid>
            <Grid item md={6}>
              <TextField
                sx={{ width: 300 }}
                id="Phone"
                label="Phone"
                variant="outlined"
                value={phone}
                onChange={handleChange(setPhone)}
                error={errors.phone !== requiredValidationMessages.VALID}
                helperText={
                  errors.phone !== requiredValidationMessages.VALID
                    ? errors.phone
                    : ''
                }
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                required
                sx={{ width: 300 }}
                id="jobTitle"
                label="Job Title"
                variant="outlined"
                value={jobTitle}
                onChange={handleChange(setJobTitle)}
                error={errors.jobTitle !== requiredValidationMessages.VALID}
                helperText={
                  errors.jobTitle !== requiredValidationMessages.VALID
                    ? errors.jobTitle
                    : ''
                }
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                sx={{ width: 300 }}
                id="address"
                label="Address"
                variant="outlined"
                value={address}
                onChange={handleChange(setAddress)}
                error={errors.address !== requiredValidationMessages.VALID}
                helperText={
                  errors.address !== requiredValidationMessages.VALID
                    ? errors.address
                    : ''
                }
              />
            </Grid>
            <Grid item md={6}>
              <Autocomplete
                id="country-select-contact"
                sx={{
                  width: 300,
                  color: 'red',
                }}
                options={countries.countries}
                value={countryValue}
                onChange={(event: any, newValue) => {
                  setCountryValue(newValue);
                  setCountry(newValue?.label ?? '');
                }}
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.label} ({option.code}) +{option.phone}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose a country"
                    error={errors.country !== requiredValidationMessages.VALID}
                    helperText={
                      errors.country !== requiredValidationMessages.VALID
                        ? errors.country
                        : ''
                    }
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                sx={{ width: 300 }}
                id="linkedin"
                label="Linkedin Profile"
                variant="outlined"
                value={linkedin}
                onChange={handleChange(setLinkedin)}
                error={errors.linkedin !== requiredValidationMessages.VALID}
                helperText={
                  errors.linkedin !== requiredValidationMessages.VALID
                    ? errors.linkedin
                    : ''
                }
              />
            </Grid>
            <Grid item md={6}>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={type}
                onChange={handleChange(setType)}
              >
                <FormControlLabel
                  value="CLIENT"
                  control={<Radio />}
                  label="CLIENT"
                />
                <FormControlLabel
                  value="PROSPECT"
                  control={<Radio />}
                  label="PROSPECT"
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>{content.button[props.type]}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
