import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { User } from '../../models';
import { useEditUserPasswordMutation } from '../../api/usersEndpoint';
import { passwordValidation } from '../../utils/validation';
import { passwordValidationMessages } from '../../models/validation';
import { useDispatch } from 'react-redux';
import { actions as requestActions } from '../../redux/slicers/requests';
export default function PasswordDialog(props: {
  user: User | null;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [password, setPassword] = React.useState<string>('');
  const [error, setError] = React.useState<passwordValidationMessages>(
    passwordValidationMessages.VALID
  );
  const dispatch = useDispatch();
  const [editUserPassword] = useEditUserPasswordMutation();
  const handleChange = (e: any) => {
    setPassword(e.target.value);
  };
  const handleClose = () => {
    setPassword('');
    props.setOpen(false);
  };
  const handleSubmit = async () => {
    if (passwordValidation(password) === passwordValidationMessages.VALID) {
      setError(passwordValidationMessages.VALID);
      const resp = await editUserPassword({
        id: props.user?.id ?? 0,
        password: password,
      });
      if ('error' in resp) {
        dispatch(requestActions.requestFailure('Something went wrong'));
      } else {
        dispatch(requestActions.requestSuccess());
        handleClose();
      }
    } else {
      setError(passwordValidation(password));
    }
  };

  return (
    <>
      <Dialog open={props.open} onClose={handleClose}>
        <DialogTitle>Edit password</DialogTitle>
        <DialogContent sx={{ minWidth: '400px' }}>
          <DialogContentText>
            Please enter new password for the user {props.user?.email}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            error={error !== passwordValidationMessages.VALID}
            helperText={error === passwordValidationMessages.VALID ? '' : error}
            id="password"
            value={password}
            onChange={handleChange}
            label="New password"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
