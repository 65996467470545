import { Contact, ContactRecord } from "../../../models";
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import {Link } from 'react-router-dom';
import { formatPhone, getNameFromEmail } from "../../../utils/common";
import { Chip } from "@mui/material";
const TEXTSIZE = `${window.innerWidth * 0.34}px`;

export const ContactRow = ({contact ,isRecord}: { contact: ContactRecord | Contact, isRecord?: Boolean }) => {
    return (
      <TableRow
        key={contact.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          {isRecord && (
            <Chip
              label="Contact"
              color="success"
              size="small"
              sx={{ marginBottom: 2 }}
            />
          )}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Link to={`/contact?id=${contact.id}`}>
              {contact.firstname} {contact.lastname}
            </Link>
            <span
              style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                width: TEXTSIZE,
              }}
            >
              {contact.jobTitle && (
                <>
                  <span>{contact.jobTitle} at </span>
                  <span
                    style={{
                      fontWeight: 'bold',
                      color: '#3d5c5c',
                    }}
                  >
                    {`${
                      typeof contact.company === 'string'
                        ? contact.company
                        : contact.company?.name
                    }`}
                  </span>
                </>
              )}
            </span>
          </div>
        </TableCell>
        <TableCell align="right">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{contact.email}</span>
            <span>{formatPhone(contact.phone)}</span>
          </div>
        </TableCell>
        {isRecord && (
          <>
            <TableCell align="right">
              <span>
                {(contact as ContactRecord).owner
                  ? getNameFromEmail((contact as ContactRecord).owner.email)
                  : 'Name Unknown'}
              </span>
            </TableCell>
            <TableCell align="right">
              <span>
                {(contact as ContactRecord).createdAt
                  ? new Date((contact as ContactRecord).createdAt).toUTCString()
                  : 'Date Unknown'}
              </span>
            </TableCell>
          </>
        )}
      </TableRow>
    );
    
}