import { Option } from '../models';
import { api } from './api';

const tagsEndpoint = api.injectEndpoints({
    endpoints: (builder) => ({
        getTags: builder.query<any, void>({
            query: () => {
                return {
                    url: '/api/tags',
                };
            },
            transformResponse: (response: { tags: Option[] }) => {
                return (
                    response.tags.map(
                        (tag:Option) => {
                            return (
                                {
                                    id: tag.id,
                                    value: tag.label,
                                    label: tag.label
                                    
                                }
                            )
                        }
                    )
                )
            },
            providesTags: ['Tags'],
        }),
        postTag: builder.mutation<void, string>({
            query: (tagName: string) => {
                return {
                    url: '/api/tags',
                    method: 'POST',
                    body: { "label": tagName },
                };
            },
            invalidatesTags: ['Tags'],
        })
    })
})

export const { useGetTagsQuery, usePostTagMutation } = tagsEndpoint;