import Textfield from '@mui/material/TextField';
import Button, { ButtonProps } from '@mui/material/Button';
import { useAddNoteMutation} from '../../api/notesEndpoint';
import * as React from 'react'
import { styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import { useDispatch } from 'react-redux';
import { actions as requestActions } from '../../redux/slicers/requests';

const MARGINLEFT = "15px"


const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText(green[500]),
  backgroundColor: green[100],
  '&:hover': {
    backgroundColor: green[300],
  },
}));


export default function Notes(props:{companyId?:number, contactId?: number}) {
  const [content, setContent] = React.useState('');
  //API
  const [addNote] = useAddNoteMutation();
  const dispatch = useDispatch()
  
  const handleSubmit = async () => {
    const resp = await addNote({
      content,
      companyId: props.companyId,
      contactId: props.contactId,
    });
    if ('error' in resp) {
      dispatch(requestActions.requestFailure('Something went wrong'));
    } else {
      dispatch(requestActions.requestSuccess());
      setContent('')
    }
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Textfield
        sx={{
          marginLeft: MARGINLEFT,
          marginRight: '20px',
          marginTop: '10px',
        }}
        placeholder="Enter a note"
        value={content}
        onChange={(e) => {
          setContent(e.target.value);
        }}
      />
      <ColorButton
        variant="contained"
        sx={{
          width: '100px',
          marginLeft: MARGINLEFT,
          marginTop: '10px',
          paddingLeft: '0px',
          paddingRight: '0px',
          fontSize: '12px',
          color: '#166e33',
        }}
        onClick={handleSubmit}
      >
        Save note
      </ColorButton>
    </div>
  );
}