import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userId:'',
    token:'',
    isFetching: false,
    error: '',
    isAdmin: false
}
const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        loginPending: ( state )=> {
            state.isFetching = true;
        },
        loginSuccess: (state, { payload }) => {
            state.token = payload;
            state.isFetching = false;
            state.error=''
        },
        loginError: (state, { payload }) => {
            state.token = '';
            state.isFetching = false;
            state.error = payload
        },
        userInfoSuccess: (state, { payload }) => {
            state.userId = payload.id;
            state.isAdmin = payload.isAdmin;
        }
    }
})

export const { reducer, actions } = loginSlice

