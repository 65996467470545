import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { NavigationDrawer } from '../micro/navigationDrawer';
import { SuccessSnackbar, ErrorSnackbar } from '../micro/snackbar';
function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      Naos International
    </Typography>
  );
}

const mdTheme = createTheme();

export function PageSkeletton(props: { name: string; children: JSX.Element }) {
  return (
    <ThemeProvider theme={mdTheme}>
      {<ErrorSnackbar />}
      {<SuccessSnackbar />}
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <NavigationDrawer name={props.name} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            {props.children}
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
