import { CompanyRecord, CompanyContact, Company } from "../../../models";
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Link } from 'react-router-dom';
import { getNameFromEmail } from "../../../utils/common";
import { Chip } from "@mui/material";
const TEXTSIZE = `${window.innerWidth * 0.34}px`;

export const CompanyRow = ({ company, isRecord }: { company: CompanyRecord | Company, isRecord?: Boolean }) => {
    return (
      <TableRow
        key={company.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          {isRecord && (
            <Chip
              label="Company"
              color="primary"
              size="small"
              sx={{ marginBottom: 2 }}
            />
          )}

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Link to={`/company?n=${company.name}&id=${company.id}`}>
              {company.name}
            </Link>
            {company.contacts && (
              <span
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  width: TEXTSIZE,
                }}
              >
                <span style={{ fontWeight: 'bold' }}>
                  {`${company.contacts.items.length} contact${
                    company.contacts.items.length > 1 ? 's' : ''
                  }${company.contacts.items.length > 0 ? ':' : ''}`}
                </span>
                <span style={{ color: '#3d5c5c' }}>
                  {company.contacts.items
                    .reduce(
                      (prevValue: string, currentValue: CompanyContact) =>
                        prevValue +
                        ', ' +
                        `${currentValue.firstname} ${currentValue.lastname}`,
                      ''
                    )
                    .slice(1) || ''}
                </span>
              </span>
            )}
            {company.placementCount && (
              <span
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  width: TEXTSIZE,
                }}
              >
                <span style={{ fontWeight: 'bold' }}>
                  {`${company.placementCount} placement${
                    company.placementCount > 1 ? 's' : ''
                  }`}
                </span>
              </span>
            )}
          </div>
        </TableCell>
        <TableCell align="right">
          <span >
            {company.industry ? company.industry.label : 'None'}
          </span>
        </TableCell>
        {isRecord && (
          <>
            <TableCell align="right">
              {isRecord && <div style={{ marginBottom: 2 }}></div>}
              <span >
                {(company as CompanyRecord).owner
                  ? getNameFromEmail((company as CompanyRecord).owner.email)
                  : 'Name Unknown'}
              </span>
            </TableCell>
            <TableCell align="right">
              {isRecord && <div style={{ marginBottom: 2 }}></div>}

              <span>
                {(company as CompanyRecord).createdAt
                  ? new Date((company as CompanyRecord).createdAt).toUTCString()
                  : 'Date Unknown'}
              </span>
            </TableCell>
          </>
        )}
      </TableRow>
    );
}