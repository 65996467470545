import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import Select, { SelectChangeEvent } from '@mui/material/Select';

export default function SingleSelect(props: {
  required?: boolean;
  label: string, options: string[], initialValue: any | null, error:boolean,
  setSelected: React.Dispatch<React.SetStateAction<any>>
}) {
  const [type, setType] = React.useState<any>(props.initialValue ?? '');
  const handleChange = (event: SelectChangeEvent) => {
    setType(event.target.value );
  };
  React.useEffect(() => {
    props.setSelected(type);
  }, [type]);

  React.useEffect(() => {
    if (props.initialValue && props.initialValue.length > 0) {
      setType(props.initialValue);
    }
  }, [props.initialValue]);

  return (
    <Box sx={{ width: 200, height: '100px' }}>
      <FormControl fullWidth error={props.error}>
        <InputLabel id="demo-simple-select-label">{`${props.label}${
          props.required ? ' *' : ''
        }`}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={type}
          label={`${props.label}${props.required ? ' *' : ''}`}
          onChange={handleChange}
        >
          {props.options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{props.error ? 'Required' : ''}</FormHelperText>
      </FormControl>
    </Box>
  );
}
