import React from 'react';
import { emailValidation, passwordValidation } from '../../utils/validation';
import {
  passwordValidationMessages,
  emailValidationMessages,
} from '../../models/validation';
import { useAddUserMutation } from '../../api/usersEndpoint';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { SuccessSnackbar, ErrorSnackbar } from '../micro/snackbar';
import { useDispatch } from 'react-redux';
import { actions as requestActions } from '../../redux/slicers/requests';
interface Errors {
  email: emailValidationMessages;
  password: passwordValidationMessages;
}
interface State {
  email: string;
  password: string;
  isAdmin: boolean;
}
export const UserAddModal = (props: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const dispatch = useDispatch()
  const [addUser] = useAddUserMutation();
  const [state, setState] = React.useState<State>({
    email: '',
    password: '',
    isAdmin: false,
  });
  // errors hook to store errors
  const [errors, setErrors] = React.useState<Errors>({
    email: emailValidationMessages.VALID,
    password: passwordValidationMessages.VALID,
  });

  const handleClose = () => {
    //reset all fields
    setState({
      email: '',
      password: '',
      isAdmin: false,
    });
    setErrors({
      email: emailValidationMessages.VALID,
      password: passwordValidationMessages.VALID,
    });
    props.setOpen(false);
  };

  const handleSubmit = async (e: any) => {
    //VALIDATE e.target.value is not empty
    e.preventDefault();
    const passwordVal = passwordValidation(state.password);
    const emailVal = emailValidation(state.email);
    if (
      passwordVal === passwordValidationMessages.VALID &&
      emailVal === emailValidationMessages.VALID
    ) {
      const resp = await addUser(state);
      if ('error' in resp) {
        dispatch(requestActions.requestFailure('Something went wrong'));
      } else {
        dispatch(requestActions.requestSuccess());
        handleClose();
      }
    }
    setErrors({
      email: emailVal,
      password: passwordVal,
    });
  };
  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, [prop]: event.target.value });
    };
  const handleRadio = (e: any) => {
    setState({ ...state, isAdmin: e.target.value === 'true' });
  };

  //Create modal to add new company to database
  return (
    <>
      <SuccessSnackbar/>
      <ErrorSnackbar/>
      <Dialog open={props.open} onClose={handleClose} maxWidth="md">
        <DialogTitle>Add a new user</DialogTitle>
        <DialogContent style={{ marginTop: '15px' }}>
          <Grid container spacing={2}>
            <Grid item md={9}>
              <div style={{ visibility: 'hidden', height: '5px' }}>a</div>
              {
                // this is to solve a visual issue with the textfield
              }
              <TextField
                style={{ minWidth: '100%' }}
                id="email"
                label="Email"
                variant="outlined"
                value={state.email}
                onChange={handleChange('email')}
                error={errors.email !== emailValidationMessages.VALID}
                helperText={
                  errors.email !== emailValidationMessages.VALID
                    ? errors.email
                    : ''
                }
              />
            </Grid>
            <Grid item md={9}>
              <TextField
                style={{ minWidth: '100%' }}
                id="password"
                label="Password"
                variant="outlined"
                value={state.password}
                onChange={handleChange('password')}
                error={errors.password !== passwordValidationMessages.VALID}
                helperText={
                  errors.password !== passwordValidationMessages.VALID
                    ? errors.password
                    : ''
                }
              />
            </Grid>
            <Grid item md={9}>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={state.isAdmin}
                onChange={handleRadio}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Admin"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Standard"
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
