import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ContactsIcon from '@mui/icons-material/Contacts';
import BusinessIcon from '@mui/icons-material/Business';
import PollIcon from '@mui/icons-material/Poll';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import EngineeringIcon from '@mui/icons-material/Engineering';
import LogoutIcon from '@mui/icons-material/Logout';
import FactoryIcon from '@mui/icons-material/Factory';
import GroupIcon from '@mui/icons-material/Group';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../api/auth';
import { CompanyModal } from '../modals/companyModal';
import { ContactModal } from '../modals/contactModal';
import Tooltip from '@mui/material/Tooltip';

export const MainListItems = () => {
  const [openCompany, setOpenCompany] = React.useState(false);
  const [openContact, setOpenContact] = React.useState(false);
  const handleCreateCompany = () => {
    setOpenCompany(true);
  };
  const handleCreateContact = () => {
    setOpenContact(true);
  };

  //ROUTER
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <CompanyModal type="add" open={openCompany} setOpen={setOpenCompany} />
      <ContactModal type="add" open={openContact} setOpen={setOpenContact} />
      <Tooltip title="Dashboard" placement="right">
        <ListItemButton
          onClick={() => {
            navigate('/dashboard');
          }}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>
      </Tooltip>
      <Tooltip title="Companies" placement="right">
        <ListItemButton
          onClick={() => {
            navigate('/companies');
          }}
        >
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary="All companies" />
        </ListItemButton>
      </Tooltip>
      <Tooltip title="Contacts" placement="right">
        <ListItemButton
          onClick={() => {
            navigate('/contacts');
          }}
        >
          <ListItemIcon>
            <ContactsIcon />
          </ListItemIcon>
          <ListItemText primary="All contacts" />
        </ListItemButton>
      </Tooltip>
      <Tooltip title="Placements" placement="right">
        <ListItemButton
          onClick={() => {
            navigate('/placements');
          }}
        >
          <ListItemIcon>
            <EngineeringIcon />
          </ListItemIcon>
          <ListItemText primary="All Placements" />
        </ListItemButton>
      </Tooltip>
      <Tooltip title="Activity" placement="right">
        <ListItemButton
          onClick={() => {
            navigate('/activity');
          }}
        >
          <ListItemIcon>
            <WorkHistoryIcon />
          </ListItemIcon>
          <ListItemText primary="User activity" />
        </ListItemButton>
      </Tooltip>
      <Tooltip title="Create Company" placement="right">
        <ListItemButton
          onClick={() => {
            handleCreateCompany();
          }}
        >
          <ListItemIcon>
            <AddCircleOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="Create company" />
        </ListItemButton>
      </Tooltip>
      <Tooltip title="Create Contact" placement="right">
        <ListItemButton
          onClick={() => {
            handleCreateContact();
          }}
        >
          <ListItemIcon>
            <AddIcCallIcon />
          </ListItemIcon>
          <ListItemText primary="Create contact" />
        </ListItemButton>
      </Tooltip>
    </React.Fragment>
  );
};

export const SecondaryListItems = () => {
  const handleLogout = () => {
    logout();
  };
  return (
    <Tooltip title="Logout" placement="right">
      <ListItemButton onClick={handleLogout}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </Tooltip>
  );
};

export const AdminListItems = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Tooltip title="Industries" placement="right">
        <ListItemButton
          onClick={() => {
            navigate('/industries');
          }}
        >
          <ListItemIcon>
            <FactoryIcon />
          </ListItemIcon>
          <ListItemText primary="Manage industries" />
        </ListItemButton>
      </Tooltip>
      <Tooltip title="Users" placement="right">
        <ListItemButton
          onClick={() => {
            navigate('/users');
          }}
        >
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary="Manage users" />
        </ListItemButton>
      </Tooltip>
      <Tooltip title="Usage Info" placement="right">
        <ListItemButton
          onClick={() => {
            navigate('/usageInfo');
          }}
        >
          <ListItemIcon>
            <PollIcon />
          </ListItemIcon>
          <ListItemText primary="Usage information" />
        </ListItemButton>
      </Tooltip>
    </React.Fragment>
  );
};
