import { HistoryData, HistoryItem, HistoryItemType, AggregatedHistoryItem, HistoryItemData } from '../../models';
import CircularProgress from '@mui/material/CircularProgress';
import Notes from '../micro/notes'
import { PlacementEvent, NoteEvent, CompanyEvent, ContactEvent } from '../micro/historyContent';
const aggregateData = (data: HistoryItem[]): AggregatedHistoryItem => {
  const historyData: AggregatedHistoryItem = {
    [HistoryItemType.PLACEMENT]:[],
    [HistoryItemType.CONTACT]: [],
    [HistoryItemType.NOTE]: [],
    [HistoryItemType.COMPANY]: []
  };
  data.forEach(item => {
      historyData[item.type].push(item.data);
  })
  
  return historyData;
}
// take date in format YYYY-MM-DD and return string in format Today, Yesterday, DD/MM/YYYY
const formatDate = (date: string): string => {
  const today = new Date()
  var yesterday = new Date()
  yesterday = new Date(yesterday.setDate(today.getDate() - 1));
  const dateToCompare = new Date(date);
  if (dateToCompare.toDateString() === today.toDateString()) {
    return `Today (${dateToCompare.toDateString()})`;
  } else if (dateToCompare.toDateString() === yesterday.toDateString()) {
    return `Yesterday (${dateToCompare.toDateString()})`;
  } else {
    return dateToCompare.toDateString();
  }
};

// function that sorts two dates in format YYYY-MM-DD
const sortDates = (a: string, b: string): number => {
  const dateA = new Date(a);
  const dateB = new Date(b);
  return dateB.getTime() - dateA.getTime();
};

const content = (type: string, data: HistoryItemData[]) => {
  console.log("🚀 ~ file: history.tsx:41 ~ content ~ data", data)
  if (type === HistoryItemType.PLACEMENT) {
    return <PlacementEvent data={data} key={type} />
  }
  if (type === HistoryItemType.NOTE) {
    return <NoteEvent data={data} key={type} />;
  }
  if (type === HistoryItemType.COMPANY) {
    return <CompanyEvent data={data} key={type} />;
  }
  if (type === HistoryItemType.CONTACT) {
    return <ContactEvent data={data} key={type} />;
  }
}

export function History(props: { history: HistoryData, companyId?: number, contactId?: number, isFetching: boolean }) {
    return (
      <div>
        <Notes companyId={props.companyId} contactId={props.contactId}/>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {props.isFetching &&  <CircularProgress/>}
          {props.history && Object.keys(props.history).sort(sortDates).map((date: string) => {
            const events = aggregateData(props.history[date])
            return (
              <div key={date}>
                <h2 style={{ textAlign: 'left' }}>{formatDate(date)}</h2>
                {
                  Object.keys(events).map((type) => {
                    return (
                      content(type, events[type as HistoryItemType])
                    );
                  })
                }
  
              </div>)
          })}
        </div>
      </div>
    );
}