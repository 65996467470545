import {
  BackendContactDetail,
  CompanyType,
  ContactPostQuery,
  Contact,
  ContactDetail
} from '../models';
import { api } from './api';

export const contactApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getContacts: builder.query<
      Contact[],
      { q: string; type?: CompanyType; companies?: number[]; users?: number[] }
    >({
      query: ({ q, type, companies, users }) => {
        const stringQuery = q && q.length > 0 ? `q=${q}` : '';
        const companyQuery = companies
          ? companies.reduce(
              (prevQuery, company) => prevQuery + `&companies[]=${company}`,
              ''
            )
          : '';
        const userQuery = users
          ? users.reduce(
              (prevQuery, user) => prevQuery + `&owners[]=${user}`,
              ''
            )
          : '';
        const typeQuery = type && type !== 'ANY' ? `&type=${type}` : '';
        return {
          url: `/api/contacts?${stringQuery}${companyQuery}${typeQuery}${userQuery}`,
        };
      },
      providesTags: ['Contacts'],

      transformResponse: (response: { contacts: Contact[] }) => {
        return response.contacts.sort((a, b) => (a.id > b.id ? 1 : -1));
      },
    }),
    getContactsWithPagination: builder.query<
      Contact[],
      {
        q: string;
        type?: CompanyType;
        companies?: number[];
        page: number;
        limit: number;
      }
    >({
      query: ({ q, type, companies, page, limit = 30 }) => {
        const stringQuery = q && q.length > 0 ? `q=${q}` : '';
        const companyQuery = companies
          ? companies.reduce(
              (prevQuery, company) => prevQuery + `&companies[]=${company}`,
              ''
            )
          : '';
        const typeQuery = type && type !== 'ANY' ? `&type=${type}` : '';
        const pageQuery = page ? `&page=${page}` : '';
        const limitQuery = limit ? `&limit=${limit}` : '';
        return {
          url: `/api/contacts?${stringQuery}${companyQuery}${typeQuery}${pageQuery}${limitQuery}`,
        };
      },
      providesTags: ['Contacts'],

      transformResponse: (response: { contacts: Contact[] }) => {
        return response.contacts.sort((a, b) => (a.id > b.id ? 1 : -1));
      },
    }),
    getContact: builder.query<ContactDetail, string>({
      query: (id: string) => {
        return {
          url: `/api/contacts/${id}`,
        };
      },
      providesTags: ['Contacts'],

      transformResponse: (response: { contact: BackendContactDetail }) => {
        return { ...response.contact, company: {id: response.contact.company.id, label: response.contact.company.name} };
      },
    }),
    addContact: builder.mutation<void, ContactPostQuery>({
      query: (contact: ContactPostQuery) => {
        return {
          url: `/api/contacts`,
          method: 'POST',
          body: contact,
        };
      },
      invalidatesTags: ['Contacts', 'Companies'],
    }),
    editContact: builder.mutation<
      void,
      { body: ContactPostQuery; contactId: number }
    >({
      query: (params) => {
        return {
          url: `/api/contacts/${params.contactId}`,
          method: 'PUT',
          body: params.body,
        };
      },
      invalidatesTags: ['Contacts', 'Companies'],
    }),
    deleteContact: builder.mutation<void, number>({
      query: (contactId) => {
        return {
          url: `/api/contacts/${contactId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags:['Companies', 'Contacts']
    })
  }),
});

export const {
  useGetContactsQuery,
  useAddContactMutation,
  useEditContactMutation,
  useGetContactQuery,
  useGetContactsWithPaginationQuery,
  useDeleteContactMutation,
} = contactApi;
