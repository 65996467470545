import { configureStore } from "@reduxjs/toolkit";
import { api } from "../api/api";
import { reducer as login } from "./slicers/users";
import { reducer as requests } from './slicers/requests';

export const store = configureStore({

    reducer: {
        [api.reducerPath]: api.reducer,
        login, requests   },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware)
})  