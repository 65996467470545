import { ActivityResult, DashboardRecord, UsageInfo } from '../models';
import { api } from './api';

const DashboardApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getRecords: builder.query<{ records: DashboardRecord[] }, void>({
      query: () => {
        return {
          url: `/api/dashboard/recent-records`,
        };
      },
      providesTags: ['Dashboard'],
    }),
    getUsageInfo: builder.query<
      UsageInfo,
      {
        userId?: string;
        startDate?: string;
        endDate?: string;
      }
    >({
      query: ({ userId, startDate, endDate }) => {
        const userQuery = userId !== '' ? `userId=${userId}` : '';
        const fromQuery = startDate !== '' ? `from=${startDate}` : '';
        const toQuery = endDate !== '' ? `to=${endDate}` : '';
        return {
          url: `/api/dashboard/usage-info?${userQuery}&${toQuery}&${fromQuery}`,
        };
      },
      providesTags: ['Dashboard'],
    }),
    getActivities: builder.query<
      ActivityResult,
      {
        userId?: string;
        startDate?: string;
        endDate?: string;
      }
    >({
      query: ({ userId, startDate, endDate }) => {
        const userQuery = userId !== '' ? `userId=${userId}` : '';
        const fromQuery = startDate !== '' ? `from=${startDate}` : '';
        const toQuery = endDate !== '' ? `to=${endDate}` : '';
        return {
          url: `/api/dashboard/activity?${userQuery}&${toQuery}&${fromQuery}`,
        };
      },
      providesTags: ['Dashboard'],
    }),
  }),
});

export const {useGetRecordsQuery, useGetActivitiesQuery, useGetUsageInfoQuery} = DashboardApi;
