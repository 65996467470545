import 'react-date-range/dist/styles.css'; // main calendar style file
import 'react-date-range/dist/theme/default.css'; // theme calendar css file
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '@mui/material/Button';
import React from 'react';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { ParsedInternalSearchQuery } from '../../../models/query';
import { Option } from '../../../models';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DateRangePicker } from 'react-date-range';
import { Menu, MenuItem } from '@mui/material';
import { formatDate } from '../../../utils/common';

function CustomizedInputBase({
  user,
  startDate,
  endDate,
  page,
}: {
  user: Option | null;
  startDate: Date;
  endDate: Date;
  page: 'activity' | 'usageInfo';
}) {
  const navigate = useNavigate();
  const userQuery = user === null ? '' : user.id;
  const handleSubmit = () => {
    navigate(
      `/${page}?userId=${userQuery}&startDate=${formatDate(
        startDate
      )}&endDate=${formatDate(endDate)}`
    );
  };
  return (
    <Paper
      component="form"
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        maxHeight: '60px',
        backgroundColor: '#f5f5f5',
      }}
      elevation={0}
      onSubmit={(e: any) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Button
        type="submit"
        sx={{ p: '10px' }}
        aria-label="search"
        variant="contained"
      >
        Search
      </Button>
    </Paper>
  );
}

export const ActivitySearchBar = (props: {
  locationProps: ParsedInternalSearchQuery;
  users?: Option[];
  page: 'activity' | 'usageInfo';
}) => {
  const [selectedUser, setSelectedUser] = React.useState<Option | null>(null);
  const [startDate, setStartDate] = React.useState<Date>(new Date());
  const [endDate, setEndDate] = React.useState<Date>(new Date());

  const handleSelect = (ranges: any) => {
    console.log(ranges);
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
  };
  const selectionRange = {
    startDate,
    endDate,
    key: 'selection',
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          maxHeight: '10',
          flexDirection: 'row',
          marginBottom: 10,
        }}
      >
        <div
          style={{
            margin: '1',
            minWidth: '120',
            maxHeight: '10',
            marginRight: '5',
            display: 'flex',
            flexDirection: 'row',
            padding: '2px 4px',
          }}
        >
          <Autocomplete
            filterSelectedOptions
            disablePortal
            id="combo-box-demo"
            options={props.users ?? []}
            value={selectedUser}
            getOptionLabel={(option) => option.label}
            onChange={(event: any, sUsers) => {
              setSelectedUser(sUsers);
            }}
            sx={{ width: 200 }}
            renderInput={(params) => (
              <TextField {...params} required label="Users" />
            )}
          />
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            variant="contained"
            sx={{
              marginLeft: 1,
              backgroundColor: 'orange',
              height: 45,
              marginTop: 0.7,
            }}
          >
            Date
            <ArrowDropDownIcon />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem>
              <DateRangePicker
                ranges={[selectionRange]}
                onChange={handleSelect}
                showPreview={false}
              />
            </MenuItem>
          </Menu>
        </div>
        <CustomizedInputBase
          user={selectedUser}
          startDate={startDate}
          endDate={endDate}
          page={props.page}
        />
      </div>
    </>
  );
};
