import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import { PageSkeletton } from '../components/shared/pageSkeletton';
import { parseSearchQuery } from '../utils/common';
import { useLocation } from 'react-router-dom';
import { Company, Contact, NoteRecord, Placement } from '../models';
import React from 'react';
import { useGetUsersOptionsQuery } from '../api/usersEndpoint';
import { PlacementRow } from '../components/shared/rows/placementRow';
import { ActivitySearchBar } from '../components/shared/searchbars/activitySearch';
import { useGetActivitiesQuery } from '../api/dashboardEndpoint';
import { CompanyRow } from '../components/shared/rows/companyRow';
import { ContactRow } from '../components/shared/rows/contactRow';
import { NoteRow } from '../components/shared/rows/noteRow';
import { Typography } from '@mui/material';


export function ActivityPage() {
  //STATE

  //GETTING QUERY PARAMS
  const location = useLocation();
  const locationProps = parseSearchQuery(location.search.slice(1));

  //GETTING DATA
  const { data: users } = useGetUsersOptionsQuery();
  const {
    data: activities,
    isFetching,
    refetch,
  } = useGetActivitiesQuery({
    userId: locationProps['userId'],
    endDate: locationProps['endDate'],
    startDate: locationProps['startDate'],
  });
  console.log(activities)
  React.useEffect(() => {
    refetch();
  }, [location]);


  return (
    <PageSkeletton name="User Activity">
      <>
        <ActivitySearchBar
          locationProps={locationProps}
          users={users}
          page="activity"
        />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            {isFetching && <LinearProgress />}

            <TableBody>
              {!isFetching && activities && (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <Typography
                    sx={{
                      marginLeft: 1,
                      marginTop: 3,
                      fontWeight: 'bold',
                    }}
                    variant="body1"
                  >
                    Companies created: {activities.companies.count}
                  </Typography>
                </TableRow>
              )}
              {!isFetching &&
                activities &&
                activities.companies.count > 0 &&
                activities.companies.items.map((company: Company) => (
                  <CompanyRow company={company} />
                ))}
            </TableBody>
          </Table>
          <Table
            sx={{
              minWidth: 650,
              borderTop: '1.5px solid',
              borderColor: '#f5f5f5',
            }}
            aria-label="simple table"
          >
            <TableBody>
              {!isFetching && activities && (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <Typography
                    sx={{
                      marginLeft: 1,
                      marginTop: 3,
                      fontWeight: 'bold',
                    }}
                    variant="body1"
                  >
                    Contacts created: {activities.contacts.count}
                  </Typography>
                </TableRow>
              )}
              {!isFetching &&
                activities &&
                activities.contacts.count > 0 &&
                activities.contacts.items.map((contact: Contact) => (
                  <ContactRow contact={contact} />
                ))}
            </TableBody>
          </Table>
          <Table sx={{
            minWidth: 650, borderTop: '1.5px solid',
              borderColor: '#f5f5f5', }} aria-label="simple table">
            <TableBody>
              {!isFetching && activities && (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <Typography
                    sx={{
                      marginLeft: 1,
                      marginTop: 3,
                      fontWeight: 'bold',
                    }}
                    variant="body1"
                  >
                    Placements created: {activities.placements.count}
                  </Typography>
                </TableRow>
              )}
              {!isFetching &&
                activities &&
                activities.placements.count > 0 &&
                activities.placements.items.map((placement: Placement) => (
                  <PlacementRow placement={placement} />
                ))}
            </TableBody>
          </Table>
          <Table sx={{ minWidth: 650 , borderTop: '1.5px solid',
              borderColor: '#f5f5f5', }} aria-label="simple table">
            <TableBody>
              {!isFetching && activities && (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <Typography
                    sx={{
                      marginLeft: 1,
                      marginTop: 3,
                      fontWeight: 'bold',
                    }}
                    variant="body1"
                  >
                    Notes created: {activities.notes.count}
                  </Typography>
                </TableRow>
              )}
              {!isFetching &&
                activities &&
                activities.notes.count > 0 &&
                activities.notes.items.map((note: NoteRecord) => (
                  <NoteRow note={note} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </PageSkeletton>
  );
}
