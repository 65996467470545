import { PageSkeletton } from '../components/shared/pageSkeletton';
import { useGetUsageInfoQuery } from '../api/dashboardEndpoint';
import { List, ListItem, ListItemText, Typography } from '@mui/material';
import { ActivitySearchBar } from '../components/shared/searchbars/activitySearch';
import { formatDate, parseSearchQuery } from '../utils/common';
import { useLocation } from 'react-router-dom';
import { useGetUsersOptionsQuery } from '../api/usersEndpoint';
import { UsageActivity } from '../models';
import LinearProgress from '@mui/material/LinearProgress';

function UserActivity({ count, activities }: { count?: number;  activities?: UsageActivity[] }) {
    console.log("🚀 ~ file: UsageInfo.tsx:11 ~ UserActivity ~ activities", activities)
    
    return (
      <List>
        <ListItem>
          <ListItemText
            primary="Number of connexions"
            secondary={`Count: ${count}`}
          />
        </ListItem>
        {activities?.map((activity) => 
          <ListItem>
            <ListItemText primary="Logged on" secondary={formatDate(new Date(activity.loginDate))} />
          </ListItem>
    )}
      </List>
    );
}

export function UsageInfo() {
    const { data: users } = useGetUsersOptionsQuery();

    const location = useLocation();
    const locationProps = parseSearchQuery(location.search.slice(1));

    const { data: usageInfo, isFetching } = useGetUsageInfoQuery({
      userId: locationProps['userId'],
      endDate: locationProps['endDate'],
      startDate: locationProps['startDate'],
    });
    console.log("🚀 ~ file: UsageInfo.tsx:20 ~ UsageInfo ~ usageInfo", usageInfo)

    return (
      <PageSkeletton name="Usage Info">
        <>
          <ActivitySearchBar
            locationProps={locationProps}
            users={users}
            page="usageInfo"
          />
          <Typography variant="h4"> Usage Information</Typography>
          {isFetching && <LinearProgress />}
          {usageInfo && (
            <List>
              <ListItem>
                <ListItemText
                  primary="Companies"
                  secondary={`Count: ${usageInfo.companies}`}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Contacts"
                  secondary={`Count: ${usageInfo.contacts}`}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Placements"
                  secondary={`Count: ${usageInfo.placements}`}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Connections"
                  secondary={
                    !locationProps['userId'] ? (
                      'Please select a user'
                    ) : (
                      <UserActivity
                        count={usageInfo.connections.count}
                        activities={usageInfo.connections.items}
                      />
                    )
                  }
                />
              </ListItem>
            </List>
          )}
        </>
      </PageSkeletton>
    );
}
