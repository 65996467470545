import {
  Company,
  CompanyDetail,
  CompanyType,
  CompanyPostQuery,
  Option,
} from '../models';
import { api } from './api';

export const companyApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCompanies: builder.query<
      Company[],
      {
        q: string;
        industries?: number[];
        tags?: number[];
        users?: number[];
        type?: CompanyType;
      }
    >({
      query: ({ q, industries, tags, users, type }) => {
        const stringQuery = q && q.length > 0 ? `q=${q}` : '';
        const industryQuery = industries
          ? industries.reduce(
              (prevQuery, industry) => prevQuery + `&industries[]=${industry}`,
              ''
            )
          : '';
        const tagQuery = tags
          ? tags.reduce((prevQuery, tag) => prevQuery + `&tags[]=${tag}`, '')
          : '';
        const userQuery = users
          ? users.reduce(
              (prevQuery, user) => prevQuery + `&owners[]=${user}`,
              ''
            )
          : '';
        const typeQuery = type && type !== 'ANY' ? `&type=${type}` : '';
        return {
          url: `/api/companies?${stringQuery}${industryQuery}${tagQuery}${typeQuery}${userQuery}`,
        };
      },
      providesTags: ['Companies'],

      transformResponse: (response: { companies: Company[] }) => {
        return response.companies.sort((a, b) => (a.id > b.id ? 1 : -1));
      },
    }),
    getCompaniesWithPagination: builder.query<
      Company[],
      {
        q: string;
        industries?: number[];
        tags?: number[];
        type?: CompanyType;
        page: number;
        limit: number;
      }
    >({
      query: ({ q, industries, tags, type, page, limit = 30 }) => {
        const stringQuery = q && q.length > 0 ? `q=${q}` : '';
        const industryQuery = industries
          ? industries.reduce(
              (prevQuery, industry) => prevQuery + `&industries[]=${industry}`,
              ''
            )
          : '';
        const tagQuery = tags
          ? tags.reduce((prevQuery, tag) => prevQuery + `&tags[]=${tag}`, '')
          : '';
        const typeQuery = type && type !== 'ANY' ? `&type=${type}` : '';
        const pageQuery = page !== undefined ? `&page=${page}` : '';
        const limitQuery = limit ? `&limit=${limit}` : '';
        return {
          url: `/api/companies?${stringQuery}${industryQuery}${tagQuery}${typeQuery}${pageQuery}${limitQuery}`,
        };
      },
      providesTags: ['Companies'],

      transformResponse: (response: { companies: Company[] }) => {
        return response.companies.sort((a, b) => (a.id > b.id ? 1 : -1));
      },
    }),
    getCompanyOptions: builder.query<Option[], void>({
      query: () => {
        return {
          url: `/api/companies`,
        };
      },
      providesTags: ['Companies'],

      transformResponse: (response: { companies: Company[] }) => {
        return response.companies
          .map((company) => {
            return { id: company.id, label: company.name };
          })
          .sort((a, b) => (a.id > b.id ? 1 : -1));
      },
    }),
    getCompanyCombinations: builder.query<
      { country: string; name: string }[],
      void
    >({
      query: () => {
        return {
          url: `/api/companies`,
        };
      },
      providesTags: ['Companies'],

      transformResponse: (response: { companies: Company[] }) => {
        return response.companies.map((company) => {
          return {
            name: company.name,
            country: company.country,
          };
        });
      },
    }),
    getCompany: builder.query<CompanyDetail, string>({
      query: (id: string) => {
        return {
          url: `/api/companies/${id}`,
        };
      },
      providesTags: ['Companies'],

      transformResponse: (response: { company: CompanyDetail }) =>
        response.company,
    }),
    addCompany: builder.mutation<void, CompanyPostQuery>({
      query: (company: CompanyPostQuery) => {
        return {
          url: `/api/companies`,
          method: 'POST',
          body: company,
        };
      },
      invalidatesTags: ['Companies'],
    }),
    editCompany: builder.mutation<void, { id: number; body: CompanyPostQuery }>(
      {
        query: (params) => {
          return {
            url: `/api/companies/${params.id}`,
            method: 'PUT',
            body: params.body,
          };
        },
        invalidatesTags: ['Companies'],
      }
    ),
    editCompanyIndustry: builder.mutation<
      void,
      { companyId: number; industryId: number }
    >({
      query: (params) => {
        return {
          url: `/api/companies/${params.companyId}`,
          method: 'PUT',
          body: { industryId: params.industryId },
        };
      },
      invalidatesTags: ['Companies'],
    }),
    editCompanyTags: builder.mutation<
      void,
      { companyId: number; tags: number[] }
    >({
      query: ({ companyId, tags }) => {
        return {
          url: `/api/companies/${companyId}`,
          method: 'PUT',
          body: { tags },
        };
      },
      invalidatesTags: ['Companies'],
    }),
    deleteCompany: builder.mutation<void, number>({
      query: (companyId) => {
        return {
          url: `/api/companies/${companyId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Companies', 'Contacts'],
    }),
  }),
});

export const {
  useGetCompaniesQuery,
  useGetCompanyQuery,
  useGetCompanyOptionsQuery,
  useGetCompanyCombinationsQuery,
  useGetCompaniesWithPaginationQuery,
  useAddCompanyMutation,
  useEditCompanyIndustryMutation,
  useEditCompanyMutation,
  useEditCompanyTagsMutation,
  useDeleteCompanyMutation
} = companyApi;
