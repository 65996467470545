import axios from 'axios';
import { UserLogin, UserRegister } from '../models/query';
export const userLogin = (credentials: UserLogin): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/token`,
        credentials
      );
      if (resp.status === 200) {
        sessionStorage.setItem(
          'Naoscrm',
          JSON.stringify({ token: resp.data.token })
        );
        const userResp = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/users/me`,
          {
            headers: {
              authorization: `
                ${resp.data.token}`,
            },
          }
        );
        const info: UserRegister = {
          token: resp.data.token,
          user: userResp.data.profile,
        };
        resolve(info);
      }
      reject(undefined);
    } catch (e: any) {
      console.log(e.message)
      reject(e);
    }
  });
};

export const logout = () => {
  localStorage.removeItem('naosUserToken');
  window.location.reload();
};
