export enum emailValidationMessages {
    INCORRECT = 'Invalid email or password',
    INVALID = 'Please enter a valid email',
    EMPTY = 'Email is required',
    VALID ='Valid'
}
export enum industryNameValidationMessages {
    INVALID = 'Name must contain only letters and spaces',
    EMPTY = 'Name is required',
    VALID ='Valid'
}

export enum requiredValidationMessages {
    EMPTY = 'Field is required',
    VALID = 'Valid'
}
export enum passwordValidationMessages {
    EMPTY = 'Password is required',
    VALID = 'Valid',
    TOO_SHORT = 'Password must be at least 6 characters long'
}

export enum fileValidationMessages {
    EMPTY = 'File is required',
    VALID = 'Valid',
    TOO_BIG = 'File cannot be more than 5mb'
}