import { Option, User } from '../models';
import { api } from './api';
const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<User[], void>({
      query: () => {
        return {
          url: '/api/users',
        };
      },
      providesTags: ['Users'],
      transformResponse: (response: { users: User[] }) =>
        response.users.sort((a, b) => (a.id > b.id ? 1 : -1)),
    }),
    getUsersOptions: builder.query<Option[], void>({
      query: () => {
        return {
          url: '/api/users',
        };
      },
      providesTags: ['Users'],
      transformResponse: (response: { users: User[] }): Option[] =>
        { return response.users.sort((a, b) => (a.id > b.id ? 1 : -1)).map((user) => {
          return {
            id: user.id,
            label: user.email.split('@')[0]
          }
        })
    },
    }),
    editUserPassword: builder.mutation<void, { id: number; password: string }>({
      query: (params) => ({
        url: `api/users/${params.id}`,
        method: 'PUT',
        body: { password: params.password },
      }),
    }),
    editUserEmail: builder.mutation<void, { id: number; email: string }>({
      query: ({ email, id }) => ({
        url: `api/users/${id}`,
        method: 'PUT',
        body: { email },
      }),
      invalidatesTags: ['Users'],
    }),

    addUser: builder.mutation<
      void,
      { email: string; password: string; isAdmin: boolean }
    >({
      query: (params) => ({
        url: `api/users`,
        method: 'POST',
        body: {
          email: params.email,
          password: params.password,
          isAdmin: params.isAdmin,
        },
      }),
      invalidatesTags: ['Users'],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useEditUserPasswordMutation,
  useEditUserEmailMutation,
  useAddUserMutation,
  useGetUsersOptionsQuery,
} = usersApi;
