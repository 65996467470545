import { NoteRecord } from '../../../models';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import {  getNameFromEmail } from '../../../utils/common';
import { Chip } from '@mui/material';

const TEXTSIZE = `${window.innerWidth * 0.34}px`;

export const NoteRow = ({ note, isRecord= false }: { note: NoteRecord, isRecord?: boolean }) => {
  return (
    <TableRow
      key={note.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {isRecord && (
          <Chip
            label="Note"
            color="secondary"
            size="small"
            sx={{ marginBottom: 2 }}
          />
        )}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            Note at
            {note.company ? (
              <>
                {' '}
                company:{' '}
                <a href={`/company?id=${note.company.id}`}>
                  {note.company?.name}
                </a>
              </>
            ) : (
              <>
                {' '}
                contact:{' '}
                <a href={`/contact?id=${note.contact?.id}`}>
                  {note.contact?.firstname} {note.contact?.lastname}
                </a>
              </>
            )}
          </div>
          <span
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              width: TEXTSIZE,
            }}
          >
            {note.content}
          </span>
        </div>
      </TableCell>
      <TableCell></TableCell>
      <TableCell align="right">
        {isRecord && <div style={{ marginBottom: 2 }}></div>}
        <span>
          {note.owner ? getNameFromEmail(note.owner.email) : 'Name Unknown'}
        </span>
      </TableCell>
      <TableCell align="right">
        {isRecord && <div style={{ marginBottom: 2 }}></div>}
        <span>
          {note.createdAt
            ? new Date(note.createdAt).toUTCString()
            : 'Date Unknown'}
        </span>
      </TableCell>
    </TableRow>
  );
};
