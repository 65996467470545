import { CompanyRecord,  ContactRecord, DashboardRecord, NoteRecord, PlacementRecord } from "../../../models";
import { CompanyRow } from "./companyRow";
import { ContactRow } from "./contactRow";
import { NoteRow } from "./noteRow";
import { PlacementRow } from "./placementRow";

export const DashboardRow = ({ record }: { record: DashboardRecord }) => {
    switch (record.type) {
        case 'COMPANY': 
            return (
              <CompanyRow
                company={record.data as CompanyRecord}
                isRecord={true}
              />
            );
        case 'CONTACT':
            return (
              <ContactRow
                contact={record.data as ContactRecord}
                isRecord={true}
              />
            );
        case 'PLACEMENT':
            return (
              <PlacementRow
                placement={record.data as PlacementRecord}
                isRecord={true}
              />
        ); 
      case 'NOTE':
        return (
          <NoteRow note={record.data as NoteRecord} isRecord={true} />
        )
        default:
            return <></>
    }

}