import Box from '@mui/material/Box';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import NoteIcon from '@mui/icons-material/Note';
import { HistoryItemData} from "../../models";
import { useSelector } from 'react-redux';
import { RootState } from '../../models/redux';
import { useDispatch } from 'react-redux';
import { actions as requestActions } from '../../redux/slicers/requests';
import {
  useDeleteNoteMutation,
  useEditNoteMutation,
} from '../../api/notesEndpoint';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteModal from '../modals/deleteModal';
import { getNameFromEmail } from '../../utils/common';
const boxStyle: React.CSSProperties = {
  paddingLeft: '10px',
  paddingBottom: '3px',
  paddingTop: '3px',
  width: '90%',
};
const blueBoxStyle: React.CSSProperties = {
  textAlign: 'left',
  marginLeft: '3rem',
  backgroundColor: '#e8f4f4',
  marginRight:'2rem',
};
const titleStyle: React.CSSProperties = {
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'row',
  padding: '5px',
};

const noteTitleStyle: React.CSSProperties = {
  fontWeight: 'bold'
}
export const PlacementEvent = (props: { data: HistoryItemData[] }) => {
  return (
    <>
      {props.data.length > 0 && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          
          
            {props.data.map((placement: any) => {
              return (
                <>
                  <div style={titleStyle}>
                    <AddCircleIcon
                      color="success"
                      fontSize="small"
                      sx={{ marginRight: '5px' }}
                    />
                    <div style={noteTitleStyle}>
                      {`A new placement added by ${getNameFromEmail(
                        placement.owner.email
                      )}`}
                    </div>
                  </div>

                  <Box sx={blueBoxStyle}>
                    <div
                      style={boxStyle}
                      key={'placement-content' + placement.id}
                    >
                      {placement['title']}
                    </div>
                  </Box>
                </>
              );
            })}
        </div>
      )}
    </>
  );
}

export const ContactEvent = (props: { data: HistoryItemData[] }) => {
    return (
      <>
        {props.data.length > 0 && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>

            
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {props.data.map((contact: any) => {
                  return (
                    <>
                      <div style={titleStyle}>
                        <AddCircleIcon
                          color="success"
                          fontSize="small"
                          sx={{ marginRight: '5px' }}
                        />
                        <div style={noteTitleStyle}>
                          {`A new contact added by ${getNameFromEmail(contact.owner.email)}`}
                        </div>
                      </div>
                      <Box sx={blueBoxStyle}>
                        <div
                          style={boxStyle}
                          key={'contact-content' + contact.id}
                        >
                          {contact.jobTitle && (
                            <span style={{ fontWeight: 'bold' }}>
                              {contact.jobTitle}:{' '}
                            </span>
                          )}
                          {`${contact['firstname']} ${contact['lastname']}`}
                        </div>
                      </Box>
                    </>
                  );
                })}
              </div>
          </div>
        )}
      </>
    );
};

export const CompanyEvent = (props: { data: HistoryItemData[] }) => {
    return (
      <>
        {props.data.length > 0 && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>



                {props.data.map((company: any) => {
                  return (
                    <>
                      <div style={titleStyle}>
                        <AddCircleIcon
                          color="success"
                          fontSize="small"
                          sx={{ marginRight: '5px' }}
                        />
                        <div style={noteTitleStyle}>
                          {`A new company added by ${getNameFromEmail(company.owner.email)}`}
                        </div>
                      </div>
                      <Box sx={blueBoxStyle}>
                        <div
                          style={boxStyle}
                          key={'company-content' + company.id}
                        >{`${company['name']}`}</div>
                      </Box>
                    </>
                  );
                })}

            
          </div>
        )}
      </>
    );
};

export const NoteEvent = (props: { data: HistoryItemData[] }) => {
const [editNote] = useEditNoteMutation();
const [deleteNote] = useDeleteNoteMutation();  
//REDUX STATE
  const dispatch = useDispatch();
const isAdmin = useSelector((state: RootState) => state.login.isAdmin);
const userId = useSelector((state: RootState) => state.login.userId);
//STATE
  const [focusedNote, setFocusedNote] = React.useState<HistoryItemData | null>(null);
  const [isEditing, setIsEditing] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [noteContent, setNoteContent] = React.useState('');
  const handleDelete = async () => {
    if (focusedNote !== null) {
       const resp = await deleteNote(focusedNote.id);
       if ('error' in resp) {
         dispatch(requestActions.requestFailure('Something went wrong'));
       } else {
         dispatch(requestActions.requestSuccess());
      };
    }
    else {
        dispatch(requestActions.requestFailure('Something went wrong'));
    }
  } 
  const handleConfirm = async (id: number) => {
    const resp = await editNote({ noteId: id, content: noteContent });
    if ('error' in resp) {
      console.log(resp['error']);
      dispatch(requestActions.requestFailure('Something went wrong'));
    } else {
      dispatch(requestActions.requestSuccess());
      setIsEditing(false);
    }
  }
  const handleEdit = (content:string) => {
    setNoteContent(content);
    setIsEditing(!isEditing)
  }
  const hasRights = (ownerId: number) => ownerId === parseInt(userId) || isAdmin;
  return (
  <>
    <DeleteModal
      open={openDelete}
      setOpen={setOpenDelete}
      handleConfirm={handleDelete}
    />
      {props.data.length > 0 && (
        <>
          {props.data.length > 0 && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>


                  {props.data.map((note: any) => {
                    return (
                      <>
                        <div style={titleStyle}>
                          <NoteIcon
                            fontSize="small"
                            sx={{ marginRight: '5px' }}
                          />
                          <div style={noteTitleStyle}>
                            {`A new note added by ${getNameFromEmail(note.owner.email)}`}
                          </div>
                        </div>
                        <Box sx={blueBoxStyle}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <div
                              style={{
                                ...boxStyle,
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                              key={'note-content' + note.id}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                }}
                              >
                                {isEditing && (
                                  <TextField
                                    value={noteContent}
                                    onChange={(e) => {
                                      setNoteContent(e.target.value);
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <Tooltip title="Confirm">
                                            <IconButton
                                              size="small"
                                              onClick={() => {
                                                handleConfirm(note.id);
                                              }}
                                            >
                                              <CheckIcon fontSize="small" />
                                            </IconButton>
                                          </Tooltip>
                                          <Tooltip title="Cancel">
                                            <IconButton
                                              size="small"
                                              onClick={() =>
                                                setIsEditing(!isEditing)
                                              }
                                            >
                                              <CancelIcon fontSize="small" />
                                            </IconButton>
                                          </Tooltip>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                                {!isEditing && (
                                  <div
                                    style={{
                                      textAlign: 'left',
                                      margin: 'auto 15px auto 0px',
                                    }}
                                  >{`${note['content']}`}</div>
                                )}

                                {hasRights(note.ownerId) && (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <Tooltip title="Edit note">
                                      <IconButton
                                        size="small"
                                        onClick={() => {
                                          handleEdit(note.content);
                                        }}
                                      >
                                        <EditIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete note">
                                      <IconButton
                                        onClick={() => {
                                          setOpenDelete(true);
                                          setFocusedNote(note);
                                        }}
                                      >
                                        <DeleteIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </Box>
                      </>
                    );
                  })}

            </div>
          )}
        </>
      )}
    </>
  );
};