import { emailValidationMessages, industryNameValidationMessages, requiredValidationMessages, passwordValidationMessages, fileValidationMessages } from "../models/validation";
export const emailValidation= (email: string): emailValidationMessages  => {
  if (
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email
    )
  ) {
    return emailValidationMessages.VALID;
  }
  if (email.trim() === '') {
    return emailValidationMessages.EMPTY;
  }
  return emailValidationMessages.INVALID;
};

//validate is not empty, contains only letters and spaces
export const industryLabelValidation = (name: string): string => {
  if (name.trim() === '') {
    return industryNameValidationMessages.EMPTY;
  }
  if (
    /^[a-zA-Z ]+$/.test(name)
  ) {
    return industryNameValidationMessages.VALID;
  }
  return industryNameValidationMessages.INVALID;
}

//validate is not empty
export const requiredValidation = (name: undefined | number | string | null): string => {
  if (name === null || name === undefined) {
    return requiredValidationMessages.EMPTY;
  }
  else if (typeof name === 'number') {
    if (name === -1) {
      return requiredValidationMessages.EMPTY;
    }
  }
  else if (name.trim() === '' || name === 'null') {
      return requiredValidationMessages.EMPTY;
  }
      return requiredValidationMessages.VALID;

}

// validate that a string is at least 6 characters long
export const passwordValidation = (password: string): passwordValidationMessages => {
  if (password.trim() === '') {
    return passwordValidationMessages.EMPTY;
  }
  if (password.length < 6) {
    return passwordValidationMessages.TOO_SHORT;
  }
  return passwordValidationMessages.VALID;
}

export const fileValidation = (file: File | null): fileValidationMessages => {
  if (file === null) {
    return fileValidationMessages.EMPTY
  }
  if (file?.size > 5*1024*1024) {
    return fileValidationMessages.TOO_BIG
  }
  return fileValidationMessages.VALID
}
