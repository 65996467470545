import React, { useEffect } from 'react';
import { emailValidation, requiredValidation, fileValidation } from '../../utils/validation';
import { requiredValidationMessages } from '../../models/validation';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  usePostPlacementMutation,
  useEditPlacementMutation,
} from '../../api/placementEndpoint';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import countries from '../../utils/countries.json';
import { Placement, CountryType } from '../../models';
import { useDispatch } from 'react-redux';
import { actions as requestActions } from '../../redux/slicers/requests';
import _ from 'lodash';
import { getFormData } from '../../utils/common';
const prefill = (placement: Placement) => {
  const country =
    countries.countries.find((country) => country.label === placement.country)
      ?.label ?? '';
  return {
    title: placement.title,
    signatureDate: new Date(placement.signatureDate).toString(),
    country: country,
    background: placement.background,
    candidateFullname: placement.candidateFullname,
    phone: placement.phone,
    email: placement.email,
    cv: placement.cv
  };
};

const content = {
  title: { add: 'Add a Placement', edit: 'Edit Placement' },
  button: { add: 'Add Placement', edit: 'Edit Placement' },
};

interface Errors {
  title: string;
  country: string;
  candidateFullname: string;
  signatureDate: string;
  background: string;
  phone: string;
  email: string;
  cv: string;
}
interface State {
  title: string;
  country: string;
  candidateFullname: string;
  signatureDate: string | null;
  background: string;
  phone: string;
  email: string;
  cv: File | null;
}

const initialState = {
  title: '',
  country: '',
  candidateFullname: '',
  signatureDate: new Date().toString(),
  background: '',
  phone: '',
  email: '',
  cv: null,
};

const initialErrors = {
  title: requiredValidationMessages.VALID,
  country: requiredValidationMessages.VALID,
  signatureDate: requiredValidationMessages.VALID,
  background: requiredValidationMessages.VALID,
  candidateFullname: requiredValidationMessages.VALID,
  phone: requiredValidationMessages.VALID,
  email: requiredValidationMessages.VALID,
  cv: requiredValidationMessages.VALID,
};
export const PlacementModal = (props: {
  editData?: Placement;
  type: 'add' | 'edit';
  companyId: number;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [countryValue, setCountryValue] = React.useState<CountryType | null>(
    countries.countries.find(
      (country) => country.label === props.editData?.country
    ) ?? null
  );
  const [editPlacement] = useEditPlacementMutation(); 
  const [postPlacement] = usePostPlacementMutation();
  const [state, setState] = React.useState<State>(initialState);
  // errors hook to store errors
  const [errors, setErrors] = React.useState<Errors>(initialErrors);
  const dispatch = useDispatch();
    
  const handleFileInput = (e: any) => {
    // handle validations
    const file = e.target.files[0] as File;
    setState({...state, cv: file});
  };

  useEffect(() => {
    if (props.editData) {
      setState(prefill(props.editData));
      const country =
        countries.countries.find(
          (country) => country.label === props.editData?.country
        ) ?? null;
      setCountryValue(country);
    }
  }, [props]);


  const handleClose = () => {
    props.setOpen(false);
    //reset all fields
    setState(initialState);
    setCountryValue(null);
  };

  const handleSubmit = async (e: any) => {
    //VALIDATE e.target.value is not empty
    e.preventDefault();
    const validationErrors = {
      title: requiredValidation(state.title),
      country: requiredValidation(state.country),
      signatureDate: requiredValidation(state.signatureDate),
      candidateFullname: requiredValidation(state.candidateFullname),
      background: requiredValidationMessages.VALID,
      phone: requiredValidation(state.phone),
      cv: fileValidation(state.cv),
      email: emailValidation(state.email)
    };
    const valid = _.isEqual(validationErrors, initialErrors);
    var resp;
    if (valid) {
      const date = (state.signatureDate
        ? new Date(state.signatureDate)
        : new Date()).toUTCString();
      if (props.type === 'add') {
        
        const formData = getFormData({
          ...state,
          signatureDate: date,
          companyId: props.companyId,
        });

        resp = await postPlacement({
          body: formData,
        });
      }
      if (props.type === 'edit' && props.editData) {
        const formData = getFormData({
          ...state,
          signatureDate: date,
          companyId: props.companyId,
        });
        resp = await editPlacement({
          body: formData,
          placementId: props.editData.id,
        });
      }
      if (resp && 'error' in resp) {
        dispatch(requestActions.requestFailure('Something went wrong'));
      } else {
        dispatch(requestActions.requestSuccess());
        handleClose();
      }
    } 
    setErrors(validationErrors);
  };
  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, [prop]: event.target.value });
    };

  //Create modal to add new company to database
  return (
    <>
      <Dialog open={props.open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>{content.title[props.type]}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label="Position title *"
            value={state.title}
            onChange={handleChange('title')}
            fullWidth
            variant="standard"
            error={errors.title !== requiredValidationMessages.VALID}
            helperText={
              errors.title !== requiredValidationMessages.VALID
                ? errors.candidateFullname
                : ''
            }
          />
          <br></br> <br></br>
          <Grid container spacing={1}>
            <Grid item md={6}>
              <Autocomplete
                id="country-select-demo"
                sx={{
                  width: 300,
                  color: 'red',
                }}
                options={countries.countries}
                value={countryValue}
                onChange={(event: any, newValue) => {
                  setCountryValue(newValue);
                  setState({ ...state, country: newValue?.label ?? '' });
                }}
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.label} ({option.code}) +{option.phone}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Position based in *"
                    error={errors.country !== requiredValidationMessages.VALID}
                    helperText={
                      errors.country !== requiredValidationMessages.VALID
                        ? errors.country
                        : ''
                    }
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                id="candidateFullname"
                label="Candidate Name *"
                variant="outlined"
                sx={{ width: 300 }}
                value={state.candidateFullname}
                onChange={handleChange('candidateFullname')}
                error={
                  errors.candidateFullname !== requiredValidationMessages.VALID
                }
                helperText={
                  errors.candidateFullname !== requiredValidationMessages.VALID
                    ? errors.candidateFullname
                    : ''
                }
              />
            </Grid>
            <Grid item md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date of placement *"
                  inputFormat="dd/MM/yyyy"
                  value={state.signatureDate}
                  onChange={(newValue: Date | null) => {
                    setState({
                      ...state,
                      signatureDate: newValue?.toString() ?? null,
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={6}>
              <TextField
                id="phoneNumber"
                label="Phone number *"
                variant="outlined"
                sx={{ width: 300 }}
                value={state.phone}
                onChange={handleChange('phone')}
                error={errors.phone !== requiredValidationMessages.VALID}
                helperText={
                  errors.phone !== requiredValidationMessages.VALID
                    ? errors.phone
                    : ''
                }
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                id="email"
                label="Candidate Email *"
                variant="outlined"
                sx={{ width: 300 }}
                value={state.email}
                onChange={handleChange('email')}
                error={errors.email !== requiredValidationMessages.VALID}
                helperText={
                  errors.email !== requiredValidationMessages.VALID
                    ? errors.email
                    : ''
                }
              />
            </Grid>
            <Grid item md={6}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label
                  style={{
                    margin: 5,
                    color:
                      errors.cv !== requiredValidationMessages.VALID
                        ? '#d32f2f'
                        : '#9b9b9b',
                  }}
                >
                  Candidate cv
                </label>
                <input
                  type="file"
                  onChange={handleFileInput}
                  id="avatar"
                  name="avatar"
                  accept=".pdf,.doc,.docx"
                />
                {errors.cv !== requiredValidationMessages.VALID && (
                  <label style={{ color: '#d32f2f', margin: 5 }}>
                    {errors.cv}
                  </label>
                )}
              </div>
            </Grid>
            <Grid item md={6}>
              <TextField
                sx={{ width: 300 }}
                id="backgroundInfo"
                label="Background Info"
                variant="outlined"
                value={state.background}
                onChange={handleChange('background')}
                error={errors.background !== requiredValidationMessages.VALID}
                helperText={
                  errors.background !== requiredValidationMessages.VALID
                    ? errors.background
                    : ''
                }
                multiline
                rows={2}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>{content.button[props.type]}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
