import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Login } from './pages/login';
import {
  RequireAuth,
  RequireNoAuth,
  RequireAdmin,
} from './utils/routeProtection';
import Dashboard from './pages/dashboard';
import { SearchPage } from './pages/search';
import { IndustryPage } from './pages/industry';
import { UserPage } from './pages/usermanagement';
import { AllCompanyPage } from './pages/allcompany';
import { AllContactPage } from './pages/allcontact';
import { CompanyPage } from './pages/company';
import { ContactPage } from './pages/contact';
import { PlacementPage } from './pages/placement';
import { AllPlacementPage } from './pages/allplacement';
import { ActivityPage } from './pages/Activity';
import { UsageInfo } from './pages/UsageInfo';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route
            path="login"
            element={
              <RequireNoAuth>
                <Login />
              </RequireNoAuth>
            }
          />
          <Route
            path="/search"
            element={
              <RequireAuth>
                <SearchPage />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/company"
            element={
              <RequireAuth>
                <CompanyPage />
              </RequireAuth>
            }
          />
          <Route
            path="/activity"
            element={
              <RequireAuth>
                <ActivityPage />
              </RequireAuth>
            }
          />
          <Route
            path="/contact"
            element={
              <RequireAuth>
                <ContactPage />
              </RequireAuth>
            }
          />
          <Route
            path="/companies"
            element={
              <RequireAuth>
                <AllCompanyPage />
              </RequireAuth>
            }
          />
          <Route
            path="/contacts"
            element={
              <RequireAuth>
                <AllContactPage />
              </RequireAuth>
            }
          />
          <Route
            path="/placements"
            element={
              <RequireAuth>
                <AllPlacementPage />
              </RequireAuth>
            }
          />
          <Route
            path="/placement"
            element={
              <RequireAuth>
                <PlacementPage />
              </RequireAuth>
            }
          />
          <Route
            path="/industries"
            element={
              <RequireAdmin>
                <IndustryPage />
              </RequireAdmin>
            }
          />
          <Route
            path="/users"
            element={
              <RequireAdmin>
                <UserPage />
              </RequireAdmin>
            }
          />
          <Route
            path="/usageInfo"
            element={
              <RequireAdmin>
                <UsageInfo />
              </RequireAdmin>
            }
          />
          <Route
            path="*"
            element={
              <main style={{ padding: '1rem' }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
