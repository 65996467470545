import React from 'react';
import Modal from '@mui/material/Modal';
import { industryLabelValidation } from '../../utils/validation';
import { industryNameValidationMessages } from '../../models/validation';
import {
  useGetIndustriesQuery,
  useEditIndustryMutation,
  useAddIndustryMutation,
  useDeleteIndustryMutation,
} from '../../api/industryEndpoint';
import {
  useEditCompanyIndustryMutation
} from '../../api/companyEndpoint';
import { Option } from '../../models';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SingleSelect from '../micro/singleSelect';
import { useDispatch } from 'react-redux';
import { actions as requestActions } from '../../redux/slicers/requests';
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export const EditModal = (props: {
  industry: Option;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [label, setLabel] = React.useState(props.industry.label);
  const [error, setError] = React.useState<string>('');
  const dispatch = useDispatch();
  const [editIndustry] = useEditIndustryMutation();
  const handleInputChange = (e: any) => {
    setLabel(e.target.value);
  };
  const handleClose = () => {
    setLabel(props.industry.label);
    setError('');
    props.setOpen(false);
  };
  const handleSubmit = async (e: any) => {
    //VALIDATE e.target.value is not empty
    e.preventDefault();
    if (
      industryLabelValidation(label) === industryNameValidationMessages.VALID
    ) {
      const resp = await editIndustry({ id: props.industry.id, label: label });
      if ('error' in resp) {
        dispatch(requestActions.requestFailure('Something went wrong'));
      } else {
        dispatch(requestActions.requestSuccess());
        props.setOpen(false);
      }
    } 
      setError(industryLabelValidation(label));
  };

  return (
    <>
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <form
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            marginTop: '9px',
          }}
          onSubmit={handleSubmit}
        >
          <TextField
            error={error !== ''}
            value={label}
            onChange={handleInputChange}
            sx={{ ml: 1, flex: 1 }}
            label={error !== '' ? error : 'Edit industry'}
          />
          <Button
            type="submit"
            sx={{ p: '15px', marginLeft: '10px' }}
            aria-label="update"
            variant="contained"
          >
            Update
          </Button>
        </form>
      </Box>
      </Modal>
      </>
  );
};

export const AddModal = (props: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [label, setLabel] = React.useState('');
  const [error, setError] = React.useState<string>('');
  const dispatch = useDispatch()
  const [addIndustry] = useAddIndustryMutation();
  const handleInputChange = (e: any) => {
    setLabel(e.target.value);
  };
  const handleClose = () => {
    setLabel('');
    setError('');
    props.setOpen(false);
  };
  const handleSubmit = async (e: any) => {
    //VALIDATE e.target.value is not empty
    e.preventDefault();
    if (
      industryLabelValidation(label) === industryNameValidationMessages.VALID
    ) {
      const resp = await addIndustry(label);
       if ('error' in resp) {
         dispatch(requestActions.requestFailure('Something went wrong'));
       } else {
         dispatch(requestActions.requestSuccess());
         handleClose();
       }
    } 
      setError(industryLabelValidation(label));
  };

  return (
    <>

    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <form
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            marginTop: '9px',
          }}
          onSubmit={handleSubmit}
        >
          <TextField
            error={error !== ''}
            value={label}
            onChange={handleInputChange}
            label={error !== '' ? error : 'Add industry'}
          />
          <Button
            type="submit"
            sx={{ p: '15px', marginLeft: '25px' }}
            aria-label="update"
            variant="contained"
          >
            Create
          </Button>
        </form>
      </Box>
      </Modal>
      </>
  );
};

export const DeleteModal = (props: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  industry: Option;
}) => {
  const [deleteIndustry] = useDeleteIndustryMutation();
  const dispatch = useDispatch()
  const handleClose = () => {
    props.setOpen(false);
  };
  const handleConfirm = async (e: any) => {
    e.preventDefault();
    const resp = await deleteIndustry(props.industry.id);
    if ('error' in resp) {
      dispatch(requestActions.requestFailure('Something went wrong'));
    } else {
      dispatch(requestActions.requestSuccess());
      handleClose();
    }
  };

  return (
    <>
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          variant="h5"
          style={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          Are you sure you want to delete {props.industry.label} industry?
        </Typography>
        <br />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            sx={{ p: '5px', marginLeft: '5px' }}
            aria-label="update"
            variant="contained"
            color="success"
            onClick={handleConfirm}
          >
            Confirm
          </Button>
          <Button
            sx={{ p: '10px', marginLeft: '5px' }}
            aria-label="update"
            variant="contained"
            color="error"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div>
      </Box>
      </Modal>
    </>
  );
};

export const UpdateIndustryModal = (props: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentIndustry: Option;
  companyId: number;
}) => {
  const [industry, setIndustry] = React.useState<Option>(props.currentIndustry);
  const dispatch = useDispatch()
  const { data: industries } = useGetIndustriesQuery();
  const industryOptions = industries?.map((industry: Option) => industry.label)
  const handleClose = () => {
    setIndustry(props.currentIndustry);
    props.setOpen(false);
  };
  const [editCompanyIndustry] = useEditCompanyIndustryMutation();
  const handleLabelChange = (label: string) => {
    const selectedIndustry = industries?.find(industry => industry.label === label) ?? props.currentIndustry
    setIndustry(selectedIndustry);
  }

  const handleSubmit = async (e:any) => {
    e.preventDefault();
    const resp = await editCompanyIndustry({companyId: props.companyId, industryId: industry.id});
     if ('error' in resp) {
       dispatch(requestActions.requestFailure('Something went wrong'));
     } else {
       dispatch(requestActions.requestSuccess());
       handleClose();
     }
  }
  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              marginTop: '9px',
            }}
            onSubmit={handleSubmit}
          >
            <Typography variant="h6">Update the industry</Typography>
            <br></br>
            <div style={{display:'flex', flexDirection:'row'}}>
              {industryOptions && (
                <SingleSelect
                  label="Industry"
                  error={false}
                  options={industryOptions}
                  setSelected={handleLabelChange}
                  initialValue={industry.label}
                />
              )}
              <Button
                type="submit"
                sx={{ p: '15px', marginLeft: '25px', mb: 5 }}
                aria-label="update"
                variant="contained"
              >
                Update
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </>
  );
}