export interface OptionType {
    title: string;
}

export type SearchEntity = 'company' | 'contact';

export type Option = {
    id: number;
    label: string;
}

export type CompanyType = 'PROSPECT' | 'CLIENT' | 'ANY';

export type Owner = {
    id: number;
    email: string
}

export interface CompanyContact {
    id: number;
    firstname: string;
    lastname: string;
    jobTitle: string;
    owner: Owner;
}
export interface Company {
    id: number;
    name: string;
    contacts?: { items: CompanyContact[]};
    count: number;
    industry: Option;
    country: string;
    placementCount: number;
}

export interface CompanyRecord extends Company {
    createdAt: string;
    owner: Owner;
}

export interface CompanyPostQuery {
    name: string;
    industryId: number ;
    type: CompanyType;
    tags?: Option | null;
    address: string;
    website: string;
    backgroundInfo: string;
    country?: string;
}

export interface User  {
    id: number;
    email: string;
    isAdmin: Boolean;
    createdAt: string;
}

export interface Placement  {
    id: number;
    title: string;
    signatureDate: string;
    background: string;
    country: string;
    candidateFullname: string;
    ownerId: number;
    phone: string;
    email: string;
    cv: File;
    attachement: Attachement;
    company: Partial<Company>;
}
export interface Attachement {
    id: string;
    name: string;
    uploadedAt: string;
    ownerId: number;
}

export interface PlacementRecord extends Placement {
    createdAt: string;
    owner: Owner;
}

export interface PlacementPostQuery {
    title: string;
    signatureDate: string | Date | null;
    background: string;
    country: string;
    candidateFullname: string;
    phone: string;
    email?: string;
    cv: File;
    companyId: number;
}


interface ContactBase {

    firstname: string;
    lastname: string;
    jobTitle: string;

    
}
export interface Contact extends ContactBase {
    email: string;
    phone: string;
    company?: string | Company; // name of the company
    id: number;
}

export interface ContactRecord extends Contact {
    createdAt: string;
    owner: Owner;
}
export interface ContactPostQuery extends ContactBase {
    companyId: number;
    address?: string;
    country?: string;
    linkedin?: string;
    email?: string;
    phone?: string;
    type: CompanyType;
}

export interface BackendContactDetail extends ContactPostQuery {
    id: number;
    ownerId: number;
    createdAt: string;
    historyData: HistoryData;
    files : FileType[];
    company: { id: number; name: string};
}

export interface ContactDetail extends ContactPostQuery {
    id: number;
    ownerId: number;
    createdAt: string;
    historyData: HistoryData;
    files : FileType[];
    company: Option;
}

export enum HistoryItemType {
    PLACEMENT = 'PLACEMENT_SIGNATURE',
    COMPANY = 'COMPANY_CREATION',
    CONTACT = 'CONTACT_CREATION',
    NOTE = 'NOTE',
}

export interface CompanyCreationData {
        id: number;
        title: string;
        firstname: string;
        lastname: string;
        name: string;
        owner: Owner;
}

export interface PlacementSignatureData {
    id: number;
    title: string;
    owner: Owner;
}
export type HistoryItemData = CompanyContact | CompanyCreationData | PlacementSignatureData;
    
 
export interface HistoryItem{
    type: HistoryItemType 
    data: HistoryItemData
}

export type AggregatedHistoryItem = {
    [key in HistoryItemType]: HistoryItemData[];
};
export type HistoryData = { [key: string]: HistoryItem[] }

export type FileType = {
    id: string;
    mimetype: string;
    name: string;
    ownerId: number;
}
export interface CompanyDetail {
    address: string;
    background: string;
    contacts: Contact[];
    files: FileType[];
    tags: Option[];
    country: string;
    createdAt: string;
    historyData: HistoryData;
    id: number;
    industry: Option;
    name: string;
    ownerId: number
    placements: Placement[];
    type: string;
    website: string;
}


// NOTES

export interface Note {
  content: string;
  companyId?: number;
  contactId?: number;
}

export interface NoteRecord {
    content: string;
    createdAt: string;
    owner: Owner;
    id: number;
    contact?: { id: number; firstname: string, lastname: string };
    company?: { id: number;  name: string}
}

export interface ProfileInfoData {
    [key:string] : string | undefined
}

export interface CountryType {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
}

export interface DashboardRecord {
    type: "COMPANY" | "CONTACT" | "PLACEMENT" | "NOTE"
    data: CompanyRecord | ContactRecord | PlacementRecord | NoteRecord
} 

export interface ActivityResult {
  companies: {
    count: number;
    items: Company[];
  };
  contacts: {
    count: number;
    items: Contact[];
  };
  placements: {
    count: number;
    items: Placement[];
  };
  notes: {
    count: number;
    items: NoteRecord[];
  }
}

export interface UsageActivity {
    id: number;
    userId: number;
    loginDate: string;
    ipAddress: string;
}

export interface UsageInfo {
  companies: number;
  contacts: number;
  placements: number;
  connections: {
    count?: number;
    items?: UsageActivity[];
  };
}

