import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Option } from '../../models';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, selectedValues: string[], theme: Theme) {
  return {
    fontWeight:
      selectedValues.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelect(props: {
  initialValue?: string,
  options?: Option[];
  label: string;
  setSelected: React.Dispatch<React.SetStateAction<Option[]>>;
}) {
  const theme = useTheme();
  const [selectedValues, setSelectedValues] = React.useState<string[]>([]);
  const [options, setOptions] = React.useState<string[]>([]);
  React.useEffect(() => {
    if (props.options) {
      setOptions(props.options.map((option) => option.label));
    }
  }, [props]);

  React.useEffect(() => {
    if (props.initialValue && props.initialValue.length > 0) {
      const ids = props.initialValue.split(',').map((id) => parseInt(id));
      if (props.options){
        const labels = props.options.filter((option) =>
          ids.includes(option.id)
        ).map(option => option.label);
        setSelectedValues(labels)
      }
    }
  }, [props.options, props.initialValue]);

  const handleChange = (event: SelectChangeEvent<typeof selectedValues>) => {
    const {
      target: { value },
    } = event;
    const selected = typeof value === 'string' ? value.split(',') : value;
    setSelectedValues(
      selected
    );
  };

  React.useEffect(() => {
    if (props.options) {
      props.setSelected(
        props.options.filter((option) => selectedValues.includes(option.label))
      );
    }
  },[selectedValues])
  return (
    <div>
      <FormControl sx={{ width: 200 }}>
        <InputLabel id="demo-multiple-name-label">{props.label}</InputLabel>
        <Select
          labelId={'demo-multiple-name-label-' + props.label}
          id={'demo-multiple-' + props.label}
          multiple
          value={selectedValues}
          onChange={handleChange}
          input={<OutlinedInput label={props.label} />}
          MenuProps={MenuProps}
        >
          {options.map((option) => (
            <MenuItem
              key={option}
              value={option}
              style={getStyles(option, selectedValues, theme)}
            >
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
