import { api } from './api';
const fileApi = api.injectEndpoints({
  endpoints: (builder) => ({

    invalidateCache: builder.mutation<void, void>({
      query: () => {
            return {
            url: `/api/health`,
        };
      },
      invalidatesTags: ['Companies', 'Contacts'],
    }),
  }),
});

export const {
useInvalidateCacheMutation
} = fileApi;
