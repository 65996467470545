
import { Contact } from "../../models"
import ContactsIcon from '@mui/icons-material/Contacts';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

export default function CompanyContacts(props: { name: string; contacts: Contact[], isFetching: boolean }) {
  const isEmpty = (contact: Contact) => {
    return !(contact.email || contact.phone);
  }
  console.log(props.contacts);
  return <div >
        {props.isFetching && <CircularProgress/>}
        {props.contacts.map(contact => { 
            return (
              <Grid
                container
                spacing={2}
                key={'container' + contact.id}
              >
                <Grid item xs={1} key={'item 1' + contact.id}>
                  <ContactsIcon />
                </Grid>
                <Grid
                  rowSpacing={{ md: 0 }}
                  container
                  item
                  xs={11}
                  key={'item 2' + contact.id}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'left',
                    paddingBottom: '0px',
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {!isEmpty(contact) && (
                      <>
                        <Grid item md={5} key={'item 3' + contact.id}>
                          <div style={{ fontWeight: 'bold', color: '#2b80b8' }}>
                            <Link to={`/contact?id=${contact.id}`}>
                              {contact.firstname} {contact.lastname}
                            </Link>
                          </div>
                          <div>
                            {`${contact.jobTitle} at `}
                            <span
                              style={{ fontWeight: 'bold', color: '#646464' }}
                            >
                              {props.name}
                            </span>
                          </div>
                        </Grid>
                        <Grid
                          item
                          md={6}
                          key={'item 4' + contact.id}
                          style={{ textAlign: 'center' }}
                        >
                          {contact.email ?? '-'}
                        </Grid>
                        <Grid
                          item
                          md={4}
                          key={'item 5' + contact.id}
                          style={{ textAlign: 'center' }}
                        >
                          {contact.phone ?? '-'}
                        </Grid>
                      </>
                    )}
                    {isEmpty(contact) && (
                      <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '0px', height:'72px'}}>
                        <div style={{ fontWeight: 'bold', color: '#2b80b8' }}>
                          <Link to={`/contact?id=${contact.id}`}>
                            {contact.firstname} {contact.lastname}
                          </Link>
                        </div>
                        <div>
                          {`${contact.jobTitle} at `}
                          <span
                            style={{ fontWeight: 'bold', color: '#646464' }}
                          >
                            {props.name}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            );
        })}
       
    </div>
}