import React from 'react';
import { cleanObject, hasDuplicateCompany } from '../../utils/common';
import { requiredValidation } from '../../utils/validation';
import { requiredValidationMessages } from '../../models/validation';
import { CompanyDetail, CompanyType, Option, CountryType } from '../../models';
import {
  useGetCompanyCombinationsQuery,
  useAddCompanyMutation,
  useEditCompanyMutation,
} from '../../api/companyEndpoint';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SingleSelect from '../micro/singleSelect';
import { useGetIndustriesQuery } from '../../api/industryEndpoint';
import { useGetTagsQuery, usePostTagMutation } from '../../api/tagsEndpoint';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import countries from '../../utils/countries.json';
import { useDispatch } from 'react-redux';
import { actions as requestActions } from '../../redux/slicers/requests';
import CreatableSelect from 'react-select/creatable';
import { useNavigate } from 'react-router-dom';
import DuplicateModal from './duplicateModal';

interface Errors {
  name: string;
  address: string;
  website: string;
  tags: string;
  background: string;
  industry: string;
}
interface State {
  name: string;
  address: string;
  website: string;
  tags: readonly Option[] | null;
  background: string;
  industry: string | null;
  type: CompanyType;
  country: string;
}

export const CompanyModal = (props: {
  editData?: CompanyDetail;
  type: 'add' | 'edit';
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { data: industries } = useGetIndustriesQuery();
  const navigate = useNavigate();
  const { data: tags } = useGetTagsQuery();
  const { data: companies } = useGetCompanyCombinationsQuery(); // all companies
  const [addCompany] = useAddCompanyMutation();
  const [editCompany] = useEditCompanyMutation();
  const [postTag] = usePostTagMutation();
  const [countryValue, setCountryValue] = React.useState<CountryType | null>(
    null
  );
  const dispatch = useDispatch();
  const [openDuplicateModal, setOpenDuplicateModal] = React.useState(false);
  const [cleanedPostCompanyData, setCleanedPostCompanyData] = React.useState<Partial<State>>({})
  const [state, setState] = React.useState<State>({
    name: '',
    address: '',
    website: '',
    tags: null,
    background: '',
    industry: null,
    type: 'PROSPECT',
    country: '',
  });
  // errors hook to store errors
  const [errors, setErrors] = React.useState<Errors>({
    name: requiredValidationMessages.VALID,
    address: '',
    website: '',
    tags: '',
    background: '',
    industry: requiredValidationMessages.VALID,
  });
  const options = industries?.map((industry) => industry.label);

  React.useEffect(() => {
    if (props.editData) {
      prefill(props.editData);
    }
  }, [props.editData, props.open]);
  const handleClose = () => {
    //reset all fields
    setState({
      name: '',
      address: '',
      website: '',
      tags: null,
      background: '',
      industry: null,
      type: 'PROSPECT',
      country: '',
    });
    setCountryValue(null);
    props.setOpen(false);
  };
  const handleSingleSelect = (prop: keyof State) => {
    return (value: Option) => {
      setState({ ...state, [prop]: value });
    };
  };


  const handleResp = async (resp: any) => {
    if (resp && 'error' in resp) {
      dispatch(requestActions.requestFailure(resp.error.data.message));
      console.log(resp['error']);
    } else {
      dispatch(requestActions.requestSuccess());
      handleClose();
      if (props.type === 'add') {
        navigate(`/company?id=${resp?.data?.companyId}`);
      }
    }
};

  const handleAdd = async (postCompanyData: any) => {
    const resp: any = await addCompany(cleanObject(postCompanyData));
    handleResp(resp);
  };


  const handleEdit = async (postCompanyData: any) => {
    const resp = await editCompany({
      id: props.editData?.id ?? -1,
      body: cleanObject(postCompanyData),
    });
    handleResp(resp);
  };
  
  const handleSubmit = async (e: any) => {
    //VALIDATE e.target.value is not empty
    e.preventDefault();
    const industryId =
      industries?.filter(
        (industry) =>
          state.industry !== null && industry.label === state.industry
      )[0]?.id ?? -1;
    const industryValidation = requiredValidation(String(industryId));
    const isValid =
      requiredValidation(state.name) === requiredValidationMessages.VALID &&
      industryValidation === requiredValidationMessages.VALID;
    if (isValid) {
      const postCompanyData = {
        name: state.name,
        address: state.address,
        website: state.website,
        tags: state.tags === null ? [] : state.tags.map((tag) => tag.id),
        background: state.background,
        industryId: industryId,
        type: state.type,
        country: state.country,
      };
      if (props.type === 'add') {
        if (companies && hasDuplicateCompany(companies, postCompanyData)) {
          setCleanedPostCompanyData(cleanObject(postCompanyData));
          setOpenDuplicateModal(true);
        } else {
          handleAdd(postCompanyData)
        }
      }
      else {
        handleEdit(postCompanyData);
      }
    }
    setErrors({
      ...errors,
      name: requiredValidation(state.name),
      industry: requiredValidation(industryId),
    });
  };
  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, [prop]: event.target.value });
    };

  const handleTagsChange = (tagsArray: readonly Option[]) => {
    setState({ ...state, tags: tagsArray });
  };

  const handleCreate = async (inputValue: string) => {
    await postTag(inputValue);
  };
  const prefill = (company: CompanyDetail) => {
    const country = countries.countries.find(
      (country) => country.label === company.country
    );
    if (country) {
      setCountryValue(country);
    }
    const industry = industries?.find(
      (industry) => industry.id === company.industry.id
    );
    setState({
      name: company.name ?? '',
      address: company.address ?? '',
      website: company.website ?? '',
      tags: null, // modify when you get tags
      background: company.background ?? '',
      industry: industry?.label ?? null,
      type: company.type as CompanyType,
      country: company.country ?? '',
    });
  };
  //Create modal to add new company to database
  return (
    <>
      <Dialog open={props.open} onClose={handleClose} maxWidth="md" fullWidth>
        <DuplicateModal
          open={openDuplicateModal}
          setOpen={setOpenDuplicateModal}
          handleConfirm={() => handleAdd(cleanedPostCompanyData)}
        />
        <DialogTitle>Add a company</DialogTitle>
        <DialogContent>
          <TextField
            required
            autoFocus
            margin="dense"
            id="name"
            label="Company name"
            value={state.name}
            onChange={handleChange('name')}
            fullWidth
            variant="standard"
            error={errors.name !== requiredValidationMessages.VALID}
            helperText={
              errors.name !== requiredValidationMessages.VALID
                ? errors.name
                : ''
            }
          />
          <br></br> <br></br>
          <Grid container spacing={1}>
            <Grid item md={6}>
              <Autocomplete
                id="country-select-demo"
                sx={{ width: 300 }}
                options={countries.countries}
                value={countryValue}
                onChange={(event: any, newValue) => {
                  setCountryValue(newValue);
                  setState({ ...state, country: newValue?.label ?? '' });
                }}
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.label} ({option.code}) +{option.phone}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose a country"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                id="address"
                label="Address"
                variant="outlined"
                sx={{ width: 300 }}
                value={state.address}
                onChange={handleChange('address')}
                error={errors.address !== ''}
                helperText={errors.address}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                sx={{ width: 300 }}
                id="website"
                label="Website"
                variant="outlined"
                value={state.website}
                onChange={handleChange('website')}
                error={errors.website !== ''}
                helperText={errors.website}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                sx={{ width: 300 }}
                id="background"
                label="Background Info"
                variant="outlined"
                value={state.background}
                onChange={handleChange('background')}
                error={errors.background !== ''}
                helperText={errors.background}
                multiline
                rows={2}
              />
            </Grid>
            <Grid item md={6} style={{ width: '300px', height: '64px' }}>
              <SingleSelect
                required={true}
                error={errors.industry !== requiredValidationMessages.VALID}
                label="Industry"
                options={options ?? []}
                initialValue={options?.find(
                  (option) => option === state.industry
                )}
                setSelected={handleSingleSelect('industry')}
              />
            </Grid>
            <Grid item md={6} style={{ width: '300px', height: '64px' }}>
              <div
                style={{ width: '300px', height: '64px', overflow: 'hidden' }}
              >
                <CreatableSelect
                  styles={{
                    menu: (provided) => ({
                      backgroundColor: 'white',
                      position: 'fixed',
                      display: 'block',
                      width: '300px',
                      maxHeight: '200px',
                      overflow: 'auto',
                      zIndex: '5000',
                    }),
                  }}
                  isMulti
                  placeholder="Select your tags"
                  onChange={handleTagsChange}
                  options={tags}
                  value={state.tags}
                  onCreateOption={handleCreate}
                />
              </div>
            </Grid>
            <Grid item md={6}>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={state.type}
                onChange={handleChange('type')}
              >
                <FormControlLabel
                  value="CLIENT"
                  control={<Radio />}
                  label="CLIENT"
                />
                <FormControlLabel
                  value="PROSPECT"
                  control={<Radio />}
                  label="PROSPECT"
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
