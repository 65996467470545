import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  requestSuccess: false,
  requestFailure: false,
  error: 'Something went wrong',
};
const requestsSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    requestSuccess: (state) => {
      state.requestSuccess = true;
    },
    requestFailure: (state, {payload}) => {
      state.error = payload;
      state.requestFailure = true;
    },
    reset: (state) => {
      state.requestSuccess = false;
      state.requestFailure = false;
    },
  },
});

export const { reducer, actions } = requestsSlice;
