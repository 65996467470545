import { useGetPlacementsQuery } from "../../api/placementEndpoint"
import { Placement } from "../../models"
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

//transform date from YYYY-MM-DD to DD-MM-YYYY
const formatDate = (date: string) => {
  const dateArray = date.split('-')
  return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`
}

export default function CompanyPlacements(props: {companyId: number, companyName: string, isFetching: boolean}) {
  const { data: placements } = useGetPlacementsQuery({ companies: [props.companyId] })
    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate(
          `/placement?n=${props.companyName}&company=${props.companyId}`
        );
    }
    return (
        <div style={{cursor:'pointer'}} onClick={handleNavigate}>
          {props.isFetching && <CircularProgress/>}
        <List component="nav">
          {placements &&
            placements?.map((placement: Placement) => {
              return (
                <ListItemText
                  sx={{ pl: 1, pb: 2, textAlign: 'left' }}
                  key={placement.id}
                  primary={placement.title}
                  secondary={formatDate(placement.signatureDate)}
                />
              );
            })}
        </List>
      </div>
    );
}
      