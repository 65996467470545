import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { emailValidation } from '../utils/validation';
import { emailValidationMessages } from '../models/validation';
import { useDispatch } from 'react-redux';
import { actions as loginActions } from '../redux/slicers/users';
import { userLogin } from '../api/auth';
import { useNavigate } from 'react-router-dom';
const theme = createTheme();

export function Login() {
  // VALIDATION
  const [isValid, setIsValid] = React.useState({ email: true, login: true });
  const [errorMessage, setErrorMessage] = React.useState({
    email: '',
    login: '',
  });
  //STORE
  const dispatch = useDispatch();
  //ROUTER
  const navigate = useNavigate();
  React.useEffect(() => {
    const token = localStorage.naosUserToken;
    if (token) {
      dispatch(loginActions.loginSuccess(token));
      navigate('/dashboard');
    }
  }, []);
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    // add endpoint here
    const email = String(formData.get('email'));
    const password = String(formData.get('password'));
    const isValidEmail = emailValidation(email);
    setIsValid({
      ...isValid,
      email: isValidEmail === emailValidationMessages.VALID,
    });
    setErrorMessage({ ...errorMessage, email: isValidEmail });

    dispatch(loginActions.loginPending());

    try {
      const auth = await userLogin({ email, password });
      if (auth.status === 'error') {
        setErrorMessage({
          ...errorMessage,
          email: emailValidationMessages.INCORRECT,
        });
        setIsValid({
          ...isValid,
          login: false,
        });
        return dispatch(loginActions.loginError(auth.message));
      }
      localStorage.setItem('naosUserToken', auth.token);
      dispatch(loginActions.loginSuccess(auth.token));
      dispatch(loginActions.userInfoSuccess(auth.user));
      navigate('/dashboard');
    } catch (e: any) {
      setErrorMessage({
        ...errorMessage,
        email: emailValidationMessages.INCORRECT,
      });

      setIsValid({
        ...isValid,
        login: false,
      });
      return dispatch(loginActions.loginError('error'));
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              error={!isValid['email'] || !isValid['login']}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              helperText={
                !isValid['email'] || !isValid['login']
                  ? errorMessage['email']
                  : 'Enter your email'
              }
              autoFocus
            />
            <TextField
              error={!isValid['login']}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              helperText={
                !isValid['login']
                  ? 'Invalid email or password'
                  : 'Enter your password'
              }
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
