import { PageSkeletton } from '../components/shared/pageSkeletton';
import { useGetUsersQuery } from '../api/usersEndpoint';
import UsersList from '../components/micro/usersList';
import { Button, ButtonGroup } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DownloadIcon from '@mui/icons-material/Download';
import React from 'react';
import { UploadModal } from '../components/modals/uploadModal';
import { actions as requestActions } from '../redux/slicers/requests';
import FileService from '../api/file';
import { useDispatch } from 'react-redux';

export function UserPage() {
  const { data: users } = useGetUsersQuery();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const handleFailure = (e: any) => {
    console.log(e);
    dispatch(requestActions.requestFailure('Something went wrong'));
  };
  const handleUpload = async (
    files: FileList | null,
    setProgress: React.Dispatch<React.SetStateAction<number>>,
    setUploadStarted: React.Dispatch<React.SetStateAction<boolean>>,
    id?: number
  ) => {
    if (files !== null) {
      setUploadStarted(true);
        await FileService.importCsv(
          files[0],
          (event: any) => {
            setProgress(Math.round((100 * event.loaded) / event.total));
          },
          id
        );
    }
  };
  const handleDownload = async () => {
    try {
      FileService.exportCsv();
    } catch (e) {
      handleFailure(e);
    }
  };

  return (
    <PageSkeletton name="Users">
      <>
        <ButtonGroup>
          <Button
            startIcon={<FileUploadIcon />}
            onClick={() => {
              setOpen(true);
            }}
          >
            Import spreadsheet
          </Button>
          <Button onClick={handleDownload} startIcon={<DownloadIcon />}>
            Export spreadsheet
          </Button>
        </ButtonGroup>
        <UploadModal
          open={open}
          setOpen={setOpen}
          allowed={['xls', 'xlsx']}
          handleUpload={handleUpload}
          canSelectUser={true}
        />
        <UsersList users={users} />
      </>
    </PageSkeletton>
  );
}
