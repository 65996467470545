import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import { CompanySearchBar } from '../components/shared/searchbars/companySearch';
import { PageSkeletton } from '../components/shared/pageSkeletton';
import { useGetCompaniesQuery } from '../api/companyEndpoint';
import { useGetTagsQuery } from '../api/tagsEndpoint';
import { useGetIndustriesQuery } from '../api/industryEndpoint';
import { parseSearchQuery } from '../utils/common';
import { useLocation} from 'react-router-dom';
import { CompanyType } from '../models';
import React from 'react';
import { useGetUsersOptionsQuery } from '../api/usersEndpoint';
import { CompanyRow } from '../components/shared/rows/companyRow';


export function AllCompanyPage() {
  //STATE
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //GETTING QUERY PARAMS
  const location = useLocation();
  const locationProps = parseSearchQuery(location.search.slice(1));

  //GETTING DATA
  const { data: industries } = useGetIndustriesQuery();
  const { data: tags } = useGetTagsQuery();
  const {data: users} = useGetUsersOptionsQuery()
  const {
    data: companies,
    isFetching,
    refetch,
  } = useGetCompaniesQuery({
    q: locationProps['query'] ?? '',
    industries: locationProps['industries[]']
      ? locationProps['industries[]'].split(',').map((id) => parseInt(id))
      : [],
    tags: locationProps['tags[]']
      ? locationProps['tags[]'].split(',').map((id) => parseInt(id))
      : [],
    type: locationProps['type'] as CompanyType,
    users: locationProps['users[]']
      ? locationProps['users[]'].split(',').map((id) => parseInt(id))
      : [],
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    refetch();
  }, [location]);


  return (
    <PageSkeletton name="All companies">
      <>
        <CompanySearchBar
          locationProps={locationProps}
          industries={industries}
          tags={tags}
          users={users}
        />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            {isFetching && <LinearProgress />}
            <TableBody>
              {companies &&
                companies.length > 0 &&
                companies
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((company) => (
                    <CompanyRow company={company}/>
                  ))}
              {companies && companies.length === 0 && (
                <TableRow>
                  <TableCell>No result found.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {companies && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={companies.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </>
    </PageSkeletton>
  );
}
